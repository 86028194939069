import React, { useMemo } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    INotificationChannel,
    IUpdateNotificationStatusHook,
    IUpsertNotificationPreferenceHook,
    IVegaNotification,
    NotificationChannelName,
    NotificationFrequency,
} from '@vegaplatformui/models';
import { FormControlLabel, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { GreenTrackSwitch } from '../../utilities/green-track-switch';
import { StringCapitalizeAndSpace } from '../../utilities/string-formatter';
import { FormField } from '../../forms/form-field';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPreferenceItemTileProps {
    notification: IVegaNotification;
    notificationChannels: INotificationChannel[];
    isPhoneNumberSet: boolean;
    updateChannelPreference: (hookRequest: IUpsertNotificationPreferenceHook) => void;
    updateNotificationStatus: (hookRequest: IUpdateNotificationStatusHook) => void;
    isLoading: boolean;
    setLoading: (id: string, isLoading: boolean) => void;
}

const PreferenceItemTile: React.FC<IPreferenceItemTileProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const isNotificationEnabled = useMemo(() => {
        return props.notification.is_enabled;
    }, [props.notification]);
    const notificationFrequency = useMemo(() => {
        return props.notification.settings.frequency;
    }, [props.notification]);

    const onChangeMainSwitch = (checked: boolean, channelName: NotificationChannelName) => {
        props.setLoading(props.notification.name, true);
        const currentChannel = props.notificationChannels.find((channel) => channel.name === channelName)!;
        const hookRequest: IUpsertNotificationPreferenceHook = {
            is_enabled: checked,
            settings: {
                frequency: NotificationFrequency.Digest,
            },
            notification_channel_name: currentChannel.name,
            notification_type_name: props.notification.name,
        };

        props.updateChannelPreference(hookRequest);
    };

    const onChangeFrequencySwitch = (frequency: NotificationFrequency, channelName: NotificationChannelName) => {
        props.setLoading(props.notification.name, true);
        const currentChannel = props.notificationChannels.find((channel) => channel.name === channelName)!;
        const hookRequest: IUpsertNotificationPreferenceHook = {
            is_enabled: true,
            settings: {
                frequency: frequency,
            },
            notification_channel_name: currentChannel.name,
            notification_type_name: props.notification.name,
        };

        props.updateChannelPreference(hookRequest);
    };

    return (
        <Grid item container xs={9} className={cx(classes.NotificationTile)} direction='row' justifyContent='flex-start' alignItems='center'>
            <Grid
                className={cx(classes.ColumnMinWidth, classes.PreferenceNameTypography, classes.PreferenceTitleGrid)}
                container
                direction='column'
                justifyContent='center'
                alignItems='flex-start'
                item
                //xs={6}
                xs={12}
                sm={10}
            >
                <Grid item>
                    <Typography className={cx(classes.PreferenceNameTypography)} variant={'body1'}>
                        {StringCapitalizeAndSpace(props.notification.name)}
                    </Typography>
                </Grid>
                {(props.notification.description || props.notification.name === 'anomaly_detected') && (
                    <Grid item>
                        <Typography className={cx(classes.SubtitleTypography)} variant={'caption'}>
                            {props.notification.description}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid xs item>
                <FormControlLabel
                    control={
                        <GreenTrackSwitch
                            checked={isNotificationEnabled}
                            aria-label={`${props.notification.name} status switch is ${isNotificationEnabled ? 'enabled' : 'disabled'}`}
                            onChange={(event, checked) => {
                                // Will be used again if we end up using a bulk on/off api call for notification types (currently just going to be using email)
                                // So the API call was not imported into the new service
                                // props.setLoading(props.notification.name, true);
                                // props.updateNotificationStatus({ notification_type_id: props.notification.id, is_enabled: checked });
                                onChangeMainSwitch(checked, NotificationChannelName.Email);
                            }}
                            disabled={props.isLoading}
                        />
                    }
                    title={'Notification Status Switch'}
                    label={`${isNotificationEnabled ? 'On' : 'Off'}`}
                />
            </Grid>
            <Grid className={cx(classes.FrequencyGrid)} xs={12} item>
                <FormField
                    label={
                        <Typography variant={'body2'} fontWeight={600}>
                            Notification frequency
                        </Typography>
                    }
                    htmlFor={'frequency-button-group'}
                >
                    <ToggleButtonGroup
                        onChange={(event, value) => {
                            if (value !== null) {
                                onChangeFrequencySwitch(value, NotificationChannelName.Email);
                            }
                        }}
                        exclusive
                        value={isNotificationEnabled ? notificationFrequency : null}
                        disabled={!isNotificationEnabled || props.isLoading}
                        id={'frequency-button-group'}
                        size='small'
                        aria-label='frequency button group'
                    >
                        <ToggleButton
                            className={cx(classes.FrequencyButtonGroup)}
                            aria-label={'notification will be on-demand'}
                            value={NotificationFrequency.OnDemand}
                            key={NotificationFrequency.OnDemand}
                            size='small'
                        >
                            On-Demand
                        </ToggleButton>
                        <ToggleButton
                            className={cx(classes.FrequencyButtonGroup)}
                            aria-label={'notification will be a daily digest'}
                            value={NotificationFrequency.Digest}
                            key={NotificationFrequency.Digest}
                            size='small'
                        >
                            Daily Digest (9:00 AM PST)
                        </ToggleButton>
                    </ToggleButtonGroup>
                </FormField>
                {/*<FormControlLabel*/}
                {/*    control={*/}
                {/*        <GreenTrackSwitch*/}
                {/*            checked={isNotificationEnabled && isDigest}*/}
                {/*            aria-label={`${props.notification.name} frequency switch is on ${isDigest ? 'digest' : 'on-demand'}`}*/}
                {/*            onChange={(event, checked) => {*/}
                {/*                // Will be used again if we end up using a bulk on/off api call for notification types (currently just going to be using email)*/}
                {/*                // So the API call was not imported into the new service*/}
                {/*                // props.setLoading(props.notification.name, true);*/}
                {/*                // props.updateNotificationStatus({ notification_type_id: props.notification.id, is_enabled: checked });*/}
                {/*                onChangeFrequencySwitch(checked, NotificationChannelName.Email);*/}
                {/*            }}*/}
                {/*            disabled={props.isLoading}*/}
                {/*        />*/}
                {/*    }*/}
                {/*    title={'Notification Frequency Switch'}*/}
                {/*    disabled={!isNotificationEnabled}*/}
                {/*    label={*/}
                {/*        <Typography variant={'caption'} className={cx(classes.FrequencyLabel)}>*/}
                {/*            {isNotificationEnabled && isDigest ? 'Daily Digest (9:00 AM PST)' : isNotificationEnabled && !isDigest ? 'On-Demand' : ''}*/}
                {/*        </Typography>*/}
                {/*    }*/}
                {/*/>*/}
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IPreferenceItemTileProps>()((theme, props) => ({
    FrequencyButtonGroup: {
        textTransform: 'capitalize',
        padding: '1px 7px',
        borderRadius: '4px',
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: `${theme.palette.primary.main}30`,
            '&:hover': { backgroundColor: `${theme.palette.primary.main}50` },
        },
        '&:hover': { backgroundColor: `${theme.palette.primary.main}20` },
    },
    FrequencyGrid: { marginTop: '1rem' },
    NotificationTile: { border: '1px solid  #D0D5DD', borderRadius: '8px', padding: '1rem', marginBottom: '1rem', minWidth: '194px' },
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
    },
    ColumnCheckboxMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '90px',
        },
    },
    PreferenceNameTypography: { overflowWrap: 'break-word', fontWeight: 600 },
    PreferenceTitleGrid: { paddingRight: '0.5rem' },
    SubtitleTypography: { overflowWrap: 'break-word', color: theme.palette.text.secondary, fontWeight: 400 },
    PreferenceItem: {
        marginBottom: '1rem',
    },
    FrequencyLabel: { fontWeight: 400, color: theme.palette.text.secondary },
}));

export { PreferenceItemTile };
