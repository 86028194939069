import {
    GeminiLayout,
    MenuItems,
    menuItemState,
    MenuShepherdTour,
    OrganizationId,
    ProfilePhotoUrl,
    ShowSupportForm,
    SnackBarOptions,
} from '@vegaplatformui/sharedcomponents';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMenuItems } from '@vegaplatformui/utils';
import { useKeycloak } from '@react-keycloak-fork/web';
import { authenticationState, pageWrapperMargin, themeState } from '../../recoil/atom';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import React, { useEffect } from 'react';
import { sessionTexts, useRouteUrls } from '@vegaplatformui/utils';
// import { useNavigate } from 'react-router-dom';
import { ContactSupportController } from '../contact-support/contact-support-controller';
import { UserSettingApi } from '@vegaplatformui/apis';
import { useFlags } from 'launchdarkly-react-client-sdk';

type IGeminiLayoutControllerProps = React.PropsWithChildren;
export const PortalLayoutController = ({ children }: IGeminiLayoutControllerProps) => {
    const [theming, setThemeState] = useRecoilState(themeState);
    const [navMargin, setNavMargin] = useRecoilState(pageWrapperMargin);
    const [authenticated, setAuthenticated] = useRecoilState(authenticationState);
    const [selectedMenuItem, setSelectedMenuItem] = useRecoilState<GeminiMenuItem>(menuItemState);
    const [profilePhotoUrl, setProfilePhotoUrl] = useRecoilState(ProfilePhotoUrl);
    const { keycloak } = useKeycloak();
    const [snackbarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptions);
    const [sidebarMenuItems, setSidebarMenuItems] = useRecoilState(MenuItems);
    const showSupportForm = useRecoilValue(ShowSupportForm);
    // const [isNotNavigate, setIsNotNavigate] = useState(false);
    const [organizationId, setOrganizationId] = useRecoilState(OrganizationId);
    // const navigate = useNavigate();
    const userSettingApi = new UserSettingApi();
    const { routeUrls } = useRouteUrls({});
    const { menuItems } = useMenuItems({});
    const flags = useFlags();
    // const [vegaPageTitle, setVegaPageTitle] = useRecoilState(vegaPageTitleState);

    useEffect(() => {
        userSettingApi.keycloak = keycloak;
        userSettingApi
            .getPhoto({ username: keycloak.tokenParsed?.preferred_username })
            .then((response) => {
                setProfilePhotoUrl(response.data.details.key);
            })
            .catch((error) => {
                ('');
            });
    }, [keycloak.token]);

    // TODO simplify the logic around selecting menu items and navigate between them
    useEffect(() => {
        //Handle updating collection and navigating when menu selection changes
        const selectedItem = { ...selectedMenuItem };
        const updatedMenuItems = sidebarMenuItems.map((a) => Object.assign({}, a));

        updatedMenuItems.length > 0 &&
            updatedMenuItems.forEach(function iter(item: GeminiMenuItem) {
                if (item.isSelected) {
                    Object.assign(selectedItem, item);
                }
                Array.isArray(item.subMenu) && item.subMenu.forEach(iter);
            });
        selectedItem && setSelectedMenuItem(selectedItem);
        localStorage.setItem(sessionTexts.route, getCurrentRoute(selectedItem)!);

        // if (selectedItem.route !== window.location.pathname.split('/')[1] && !isNotNavigate && selectedItem && selectedItem.route) {
        //     setVegaPageTitle({ title: vegaPageTitle.title, subTitle: selectedItem.title });
        //     navigate(selectedItem.route);
        // }

        // setIsNotNavigate(false);
    }, [sidebarMenuItems]);

    useEffect(() => {
        const currentRouteInMenu = window.location.pathname.split('/')[1];
        if (selectedMenuItem?.route !== currentRouteInMenu && sidebarMenuItems.map((item) => item.route).includes(currentRouteInMenu)) {
            //Handle updating collection and navigating when menu selection changes

            const menuItemsUpdate: GeminiMenuItem[] = sidebarMenuItems.map((a) => Object.assign({}, a));

            menuItemsUpdate.length > 0 &&
                menuItemsUpdate.forEach(function iter(item: GeminiMenuItem) {
                    if (item.route === currentRouteInMenu) {
                        item.isSelected = true;
                    } else {
                        item.isSelected = false;
                    }
                    Array.isArray(item.subMenu) && item.subMenu.forEach(iter);
                });
            // setIsNotNavigate(true);
            setSidebarMenuItems([...menuItemsUpdate]);
        }
    }, [window.location.pathname, sidebarMenuItems]);

    //ToDo move this into hook and use memoization to compute values
    useEffect(() => {
        //Set selected nav item and route on refresh
        const filteredMainMenuItems = menuItems
            .map((a) => Object.assign({}, a))
            .filter((a) => {
                return a.enabled;
            });

        filteredMainMenuItems.forEach((menuItem) => {
            menuItem.subMenu = menuItem.subMenu?.filter((a) => {
                return a.enabled;
            });
        });
        const currentRoute = localStorage.getItem(sessionTexts.route) !== undefined ? localStorage.getItem(sessionTexts.route) : routeUrls.navigator;

        filteredMainMenuItems &&
            filteredMainMenuItems.forEach(function iter(item: GeminiMenuItem) {
                if (item.route === currentRoute) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
                Array.isArray(item.subMenu) && item.subMenu.forEach(iter);
            });

        setSidebarMenuItems(filteredMainMenuItems);
    }, [organizationId, menuItems]);

    const getCurrentRoute = (selectedItem: GeminiMenuItem): string | undefined => {
        if (selectedItem.route !== undefined) {
            return selectedItem.route;
        }

        if (localStorage.getItem(sessionTexts.route) !== null || undefined) {
            return localStorage.getItem(sessionTexts.route) ?? '';
        }

        return routeUrls.navigator.path;
    };

    const onCloseSnackbar = () => {
        setSnackbarOptions({ snackBarProps: {}, alertProps: {}, message: '' });
    };

    return (
        <>
            <GeminiLayout
                keycloak={keycloak}
                homeUrl={routeUrls.navigator.path}
                pageWrapperMargin={navMargin}
                themeState={theming}
                authenticationState={authenticated}
                setNavMargin={setNavMargin}
                setAuthenticated={setAuthenticated}
                setThemeState={setThemeState}
                selectedMenuItem={selectedMenuItem}
                menuItems={sidebarMenuItems}
                userName={keycloak?.idTokenParsed?.name}
                children={children}
                snackbarOptions={snackbarOptions}
                onCloseSnackbar={onCloseSnackbar}
                setSidebarMenuItems={setSidebarMenuItems}
                profilePhotoUrl={profilePhotoUrl}
            />
            <ContactSupportController show={showSupportForm.showSupportForm} />
        </>
    );
};
