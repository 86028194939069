import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Menu, MenuItem, ToggleButton } from '@mui/material';
import { chainEventHandlers } from 'material-ui-popup-state/chainEventHandlers';
import { Settings } from '@mui/icons-material';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { useMenuItems, useRouteUrls } from '@vegaplatformui/utils';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { IRouteUrlTab, SettingTypes } from '@vegaplatformui/models';
import { useRecoilValue } from 'recoil';
import { AuthUserRoles } from '../recoil/atom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomAppbarSettingsMenuProps {
    selectedMenuItem: GeminiMenuItem;
    onNavigateAppBarButton: (menuItem: GeminiMenuItem, tabId?: string) => void;
}

const CustomAppbarSettingsMenu: React.FC<ICustomAppbarSettingsMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { routeUrls } = useRouteUrls({});
    const { menuItems } = useMenuItems({});
    const authUserRoles = useRecoilValue(AuthUserRoles);
    const [availableOrganizationSettingsTabs, setAvailableOrganizationSettingsTabs] = React.useState<IRouteUrlTab[]>([]);

    useEffect(() => {
        setAvailableOrganizationSettingsTabs(
            routeUrls.settings.tabs.filter(
                (tab) =>
                    tab.enabled &&
                    tab.settingsLocation === SettingTypes.Organizational &&
                    tab.allowedRoles.some((role) => authUserRoles.includes(role))
            )
        );
    }, [authUserRoles]);

    const onNavigateClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, menuItem: GeminiMenuItem, tab?: string) => {
        event.stopPropagation();
        props.onNavigateAppBarButton(menuItem, tab);
    };

    return (
        <PopupState variant='popover' popupId={'settings-menu-popover'} parentPopupState={undefined} disableAutoFocus={undefined}>
            {(popupState) => {
                const onClose = () => {
                    popupState.close();
                };
                return (
                    <>
                        <StyledToolTip arrow={true} title={'Settings Menu'}>
                            <ToggleButton
                                className={cx(classes.ToggleButton)}
                                id={'settings-appbar-menu'}
                                value={'settings'}
                                size={'small'}
                                component={'a'}
                                selected={props.selectedMenuItem?.route === routeUrls.settings.route.path || popupState.isOpen}
                                aria-label={
                                    props.selectedMenuItem?.route === routeUrls.settings.route.path
                                        ? 'settings menu is currently open'
                                        : 'open menu to view navigation options for settings'
                                }
                                aria-owns={popupState.isOpen ? 'settings-selection-menu' : undefined}
                                {...bindTrigger(popupState)}
                            >
                                <Settings />
                            </ToggleButton>
                        </StyledToolTip>
                        <Menu
                            id={'settings-selection-menu'}
                            className={cx(classes.Menu)}
                            {...bindMenu(popupState)}
                            {...chainEventHandlers(bindMenu(popupState), {
                                onClose: (e: React.MouseEvent) => {
                                    onClose();
                                },
                            })}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem
                                onClick={(event) => {
                                    event.preventDefault();
                                    onClose();
                                    const menuItem = menuItems.find((item) => item.route === routeUrls.settings.route.path);
                                    menuItem && onNavigateClicked(event, menuItem, 'profile');
                                }}
                                component={'a'}
                                href={`${routeUrls.settings.route.path}/profile`}
                            >
                                Profile Settings
                            </MenuItem>
                            {availableOrganizationSettingsTabs.length > 0 && (
                                <MenuItem
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onClose();
                                        const menuItem = menuItems.find((item) => item.route === routeUrls.settings.route.path);
                                        menuItem && onNavigateClicked(event, menuItem, availableOrganizationSettingsTabs[0].id);
                                    }}
                                    component={'a'}
                                    href={`${routeUrls.settings.route.path}/${availableOrganizationSettingsTabs[0].id}`}
                                >
                                    Organization Settings
                                </MenuItem>
                            )}
                        </Menu>
                    </>
                );
            }}
        </PopupState>
    );
};

const useStyles = customMakeStyles<ICustomAppbarSettingsMenuProps>()((theme, props) => ({
    ToggleButton: {
        border: 'none',
        borderRadius: '10px',
        '&:hover': { backgroundColor: `${theme.palette.primary.main}30` },
        '&.Mui-selected': { backgroundColor: `${theme.palette.primary.main}60`, color: theme.palette.primary.main },
    },
    ListItemIcon: { minWidth: '0px !important' },
    Menu: { maxWidth: 250 },
}));

export { CustomAppbarSettingsMenu };
