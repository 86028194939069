import React, { useMemo } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Autocomplete, ListItem, ListItemText, TextField, Tooltip } from '@mui/material';
import { IOrganizationMetadata } from '@vegaplatformui/models';
import { useOrganizationsChildrenApi } from '../api-hooks/use-organization-children';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAppbarUrlNavigatorProps {}

const AppbarUrlNavigator: React.FC<IAppbarUrlNavigatorProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { organizationChildrenMetadata, setCurrentChildSelected, currentChildSelected, orgId } = useOrganizationsChildrenApi({});

    const orgMetaData = useMemo(() => {
        let metadata: IOrganizationMetadata[] = [];
        if (organizationChildrenMetadata) {
            metadata = [organizationChildrenMetadata, ...organizationChildrenMetadata.children];
        }
        return metadata;
    }, [organizationChildrenMetadata]);

    return (
        <Autocomplete
            size={'small'}
            className={cx(classes.NavigationAutocomplete)}
            value={currentChildSelected}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={orgMetaData}
            getOptionLabel={(option: IOrganizationMetadata) => option.domain_url}
            renderOption={(props, option: IOrganizationMetadata, state, ownerState) => (
                <ListItem {...props} key={option.id}>
                    <Tooltip title={`${option.domain_url}`}>
                        <ListItemText primary={`${option.domain_url}`} primaryTypographyProps={{ noWrap: true }} />
                    </Tooltip>
                </ListItem>
            )}
            getOptionDisabled={(option: IOrganizationMetadata) => window.location.hostname.includes(option.domain_url) || orgId === option.id}
            noOptionsText={'No domains found'}
            fullWidth={true}
            onChange={(e, metadata: IOrganizationMetadata | null, reason) => {
                // setCurrentChildSelected(metadata);
                if (metadata) {
                    window.open(`https://${metadata.domain_url}`, '_blank', 'noopener,noreferrer');
                }
            }}
            renderInput={(params) => <TextField {...params} fullWidth={true} variant={'standard'} placeholder='Select a client URL' />}
        />
    );
};

const useStyles = customMakeStyles<IAppbarUrlNavigatorProps>()((theme, props) => ({
    NavigationAutocomplete: { width: '40%' },
}));

export { AppbarUrlNavigator };
