import React from 'react';
import { Stack, Typography, Skeleton } from '@mui/material';
import { CloudProviderIcon } from '../../../utilities/logo-selector';
import { ParkingToggle } from '../../../parking/parking-toggle';
import IconButton from '@mui/material/IconButton';
import { FilePresent } from '@mui/icons-material';
import { CloudProvidersTypes, IResource, IResourceBusinessGroup } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';
import { getDetailsFromAzureId } from '@vegaplatformui/utils';
import { JsonViewDialog } from '../../../json-view-dialog/json-view-dialog';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResourceDetailPanelProps {
    resource?: IResource;
    isLoading: boolean;
}

const renderSkeleton = (lines = 1) => {
    return <Skeleton variant='rectangular' animation='wave' width={250} height={30 * lines} />;
};

const ResourceDetailTab: React.FC<ResourceDetailPanelProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const { vOperate } = useFlags();

    const getRow = (label: string, value: JSX.Element | string, alignStart?: boolean, className?: string) => {
        return (
            <Stack className={cx(className)} direction={'row'} justifyContent={'flex-start'} alignItems={alignStart ? 'flex-start' : 'center'}>
                <Typography className={cx(classes.label)}>{label}</Typography>
                {React.isValidElement(value) ? (
                    value
                ) : (
                    <Typography className={cx(classes.ValueTypography)}>{props.isLoading ? renderSkeleton() : value}</Typography>
                )}
            </Stack>
        );
    };

    return (
        <Stack gap={2}>
            <JsonViewDialog
                onCloseDialog={() => setIsDialogOpen(false)}
                isDialogOpen={isDialogOpen}
                title={`${props.resource?.resource_id} JSON`}
                json={JSON.stringify(props.resource?.configuration ?? { Info: 'Configuration not detected.' })}
            />
            {getRow('Provider', <CloudProviderIcon cloudProvider={props.resource?.provider_str ?? ''} />)}
            {getRow('Resource Name', props.resource?.name || '')}
            {getRow('Resource ID', props.resource?.resource_id || '')}
            {getRow('Cloud Account Name', props.resource?.provider_account?.canonical_name || '')}
            {getRow('Cloud Account ID', props.resource?.provider_account?.account_id || '')}
            {getRow(
                'Business Groupings',
                (
                    <Stack direction={'column'} spacing={1}>
                        {props.resource?.business_groups.map((bg: IResourceBusinessGroup, index) => {
                            return <Typography key={bg.id}>{bg.name}</Typography>;
                        })}
                    </Stack>
                ) || '',
                true
            )}
            {getRow('Type', props.resource?.type_str || '')}
            {getRow('Region', props.resource?.region || '')}
            {getRow(
                'JSON',
                <IconButton title={'View JSON Configuration'} onClick={() => setIsDialogOpen(true)} className={cx(classes.jsonIconButton)}>
                    <FilePresent />
                </IconButton>,
                false,
                classes.fixTopMargin
            )}
            {props.resource?.provider_str === CloudProvidersTypes.Azure.toUpperCase() && (
                <>
                    {getRow('Resource Group', getDetailsFromAzureId(props.resource.resource_id).resourceGroup)}
                    {getRow('Provider', getDetailsFromAzureId(props.resource.resource_id).provider)}
                </>
            )}
            {props.resource?.os_type && getRow('OS Type', props.resource?.os_type || '')}
            {vOperate
                ? props.resource?.is_parking_capable &&
                  getRow('Status', <ParkingToggle isLoading={props.isLoading} resource={props.resource} />, false, classes.fixTopMargin)
                : getRow('Status', props.resource?.is_running ? 'Running' : 'Stopped' ?? '')}
            {props.resource &&
                !props.resource.is_parking_capable &&
                props.resource.type_str === 'Database Instance' &&
                getRow('Status', 'Not Stoppable', false, classes.fixTopMargin)}
        </Stack>
    );
};

const useStyles = customMakeStyles<ResourceDetailPanelProps>()((theme, props) => ({
    label: {
        width: '200px',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[700],
    },
    fixTopMargin: {
        marginTop: '-.4rem',
    },
    jsonIconButton: {
        marginLeft: '-.75rem',
    },
    ValueTypography: { overflowWrap: 'anywhere' },
}));

export { ResourceDetailTab };
