import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, PaperProps, Typography } from '@mui/material';

import { ICloudProviderAccount, IParkingScheduleSummary } from '@vegaplatformui/models';
import Draggable from 'react-draggable';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConfirmDeleteDialogProps {
    scheduleToDelete?: IParkingScheduleSummary;
    schedulesToDelete: IParkingScheduleSummary[];
    isConfirmDeleteDialogOpen: boolean;
    onCloseConfirmDeleteDialog: () => void;
    confirmDeleteSchedule: (schedule: IParkingScheduleSummary) => void;
    onClickDeleteSelectedSchedules: () => void;
}

function PaperComponent(props: PaperProps) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle='#confirm-delete-account-dialog' cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

const ConfirmDeleteScheduleDialog: React.FC<IConfirmDeleteDialogProps> = (props) => {
    const { classes, cx } = useCommonStyles();

    return (
        <Dialog
            fullWidth
            open={props.isConfirmDeleteDialogOpen}
            onClose={props.onCloseConfirmDeleteDialog}
            PaperComponent={PaperComponent}
            aria-labelledby='confirm-delete-schedule-dialog'
        >
            <DialogTitle className={cx(classes.FormTitle)} style={{ cursor: 'move' }} id='confirm-delete-schedule-dialog'>
                <Grid container spacing={2} direction={'row'}>
                    <Grid item xs={12}>
                        <Typography fontWeight={'600'} variant={'h5'}>
                            Are you sure you want to delete
                            {props.schedulesToDelete && props.schedulesToDelete.length > 1
                                ? ` ${props.schedulesToDelete.length} schedules?`
                                : props.schedulesToDelete && props.schedulesToDelete.length === 1
                                  ? ` ${props.schedulesToDelete[0].name}?`
                                  : ` ${props.scheduleToDelete?.name}?`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}> You cannot undo this action </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogActions className={cx(classes.DialogActions)}>
                    <Button
                        variant={'cancel'}
                        className={cx(classes.LowercaseTextButton)}
                        disableElevation={true}
                        color={'secondary'}
                        autoFocus
                        onClick={props.onCloseConfirmDeleteDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={cx(classes.LowercaseTextButton)}
                        color={'error'}
                        variant={'contained'}
                        onClick={() => {
                            if (props.schedulesToDelete && props.schedulesToDelete.length > 0) {
                                props.onClickDeleteSelectedSchedules();
                            } else {
                                props.confirmDeleteSchedule(props.scheduleToDelete!);
                            }
                        }}
                        disableElevation={true}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IConfirmDeleteDialogProps>()((theme, props) => ({}));

export { ConfirmDeleteScheduleDialog };
