import {
    IOrganizationNotificationConfiguration,
    IPostOrganizationIntegrationRequest,
    IPutOrganizationIntegrationRequest,
} from '@vegaplatformui/models';
import { useKeycloak } from '@react-keycloak-fork/web';
import { useSetRecoilState } from 'recoil';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SnackBarOptions } from '../../recoil/atom';
import { queryKeys } from '../query-keys';
import { SnackbarErrorOutput } from '../../utilities/snackbar-error-output';
import { useMemo } from 'react';
import { NotificationsAPI } from '@vegaplatformui/apis';

export interface IUseOrganizationNotificationSettingsApiHook {
    organizationNotificationConfigurations: IOrganizationNotificationConfiguration[];
    areWebhooksLoading: boolean;
    isWebhookUpdating: boolean;
    updateWebhook: (hookRequest: IPutOrganizationIntegrationRequest) => void;
    createWebhook: (hookRequest: IPostOrganizationIntegrationRequest) => void;
}

export interface IUseOrganizationNotificationSettingsApiProps {}

export function useOrganizationNotificationSettingsApi(
    props: IUseOrganizationNotificationSettingsApiProps
): IUseOrganizationNotificationSettingsApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const queryClient = useQueryClient();
    const flags = useFlags();

    const notificationsApi = useMemo(() => {
        const apiInstance = new NotificationsAPI();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const { data: notificationChannels, isLoading: isNotificationChannelsLoading } = useQuery({
        queryKey: [queryKeys.notificationTabs.getNotificationChannels],
        queryFn: async ({ signal }) => {
            await queryClient.cancelQueries({
                queryKey: [queryKeys.notificationTabs.getNotificationChannels],
            });
            const response = await notificationsApi.getNotificationChannels(signal);
            if (response.data.data === null) return [];
            return response.data.data;
        },
        placeholderData: keepPreviousData,
        enabled: flags.notificationService && window.location.pathname.includes('notification'),
        meta: {
            errorMessage: 'Unable to get user notification channels',
        },
    });

    const { data: organizationNotificationConfigurations, isLoading: areWebhooksLoading } = useQuery({
        queryKey: [queryKeys.notificationSettings.getWebhooks, notificationChannels],
        queryFn: async ({ signal }) => {
            await queryClient.cancelQueries({
                queryKey: [queryKeys.notificationSettings.getWebhooks],
            });
            const response = await notificationsApi.getOrganizationNotificationConfigurations(signal);
            return !!response.data.configurations && response.data.configurations.length > 0
                ? response.data.configurations.map((config) => {
                      const channel = notificationChannels?.find((channel) => channel.id === config.notification_channel_id);
                      return {
                          ...config,
                          notification_channel: channel?.name,
                      } as IOrganizationNotificationConfiguration;
                  })
                : [];
        },
        placeholderData: keepPreviousData,
        enabled: flags.notificationService,
        meta: {
            errorMessage: `Unable to get organization's webhook url`,
        },
    });

    const { mutate: createWebhook, isPending: isWebhookCreating } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem saving the webhook:  ${SnackbarErrorOutput(error ?? '')}`,
            });
            queryClient.setQueryData([queryKeys.notificationSettings.getWebhooks, notificationChannels], organizationNotificationConfigurations);
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Webhook has been saved',
            });
            queryClient.invalidateQueries({ queryKey: [queryKeys.notificationSettings.getWebhooks] });
        },
        mutationFn: async (hookRequest: IPostOrganizationIntegrationRequest) => {
            if (flags.notificationService) {
                return await notificationsApi.postOrganizationNotificationConfiguration(hookRequest);
            }
        },
    });

    const { mutate: updateWebhook, isPending: isWebhookUpdating } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem saving the webhook:  ${SnackbarErrorOutput(error ?? '')}`,
            });
            queryClient.setQueryData([queryKeys.notificationSettings.getWebhooks, notificationChannels], organizationNotificationConfigurations);
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Webhook has been saved',
            });

            queryClient.invalidateQueries({ queryKey: [queryKeys.notificationSettings.getWebhooks] });
        },
        mutationFn: async (hookRequest: IPutOrganizationIntegrationRequest) => {
            if (flags.notificationService) {
                return await notificationsApi.putOrganizationNotificationConfiguration(hookRequest);
            }
        },
    });
    return {
        areWebhooksLoading: areWebhooksLoading || isNotificationChannelsLoading,
        isWebhookUpdating: isWebhookUpdating || isWebhookCreating,
        organizationNotificationConfigurations: organizationNotificationConfigurations ?? [],
        updateWebhook,
        createWebhook,
    };
}
