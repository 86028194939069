import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IUpdateNotificationStatusHook, IVegaNotification, IUpsertNotificationPreferenceHook, INotificationChannel } from '@vegaplatformui/models';
import { Grid } from '@mui/material';
import { NotificationSettingsTab } from '../../utilities/notification-settings-tab';
import { PreferenceItemTile } from './preference-item-tile';

export interface INotificationsPreferencesProps {
    notifications: IVegaNotification[];
    isInitialNotificationsLoading: boolean;
    isDefaultCommunicationItemsLoading: boolean;
    isPhoneNumberSet: boolean;
    notificationChannels: INotificationChannel[];
    updateChannelPreference: (hookRequest: IUpsertNotificationPreferenceHook) => void;
    updateNotificationStatus: (hookRequest: IUpdateNotificationStatusHook) => void;
    loadingStates: Record<string, boolean>;
    setLoading: (id: string, isLoading: boolean) => void;
}

const NotificationPreferences: React.FC<INotificationsPreferencesProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <NotificationSettingsTab
            title={'Email Notifications'}
            subtitle={'Manage received notification digests or on-demand notifications.'}
            isLoading={props.isInitialNotificationsLoading || props.isDefaultCommunicationItemsLoading}
        >
            {/*<Grid className={cx(classes.ColumnMinWidth)} item xs={2}>*/}
            {/*    <Typography>Status</Typography>*/}
            {/*</Grid>*/}
            {/*<Grid className={cx(classes.ColumnMinWidth)} item xs={10}>*/}
            {/*    <Typography>Type</Typography>*/}
            {/*</Grid>*/}
            {/*{props.notificationChannels.map((channel) => channel.name).includes(NotificationChannelName.Email) && (*/}
            {/*    <Grid className={cx(classes.ColumnCheckboxMinWidth)} item xs={2}>*/}
            {/*        <Typography>Email</Typography>*/}
            {/*    </Grid>*/}
            {/*)}*/}
            {/*{props.notificationChannels.map((channel) => channel.name).includes(NotificationChannelName.Sms) && (*/}
            {/*    <Grid*/}
            {/*        className={cx(classes.ColumnCheckboxMinWidth)}*/}
            {/*        item*/}
            {/*        xs={2}*/}
            {/*        container*/}
            {/*        spacing={0}*/}
            {/*        justifyContent='flex-start'*/}
            {/*        alignItems='center'*/}
            {/*    >*/}
            {/*        <Grid item>*/}
            {/*            <Typography>SMS</Typography>*/}
            {/*        </Grid>*/}
            {/*        {!props.isPhoneNumberSet && (*/}
            {/*            <Grid item>*/}
            {/*                <IconButtonWithAlertPopover*/}
            {/*                    buttonTitle={'SMS Issue'}*/}
            {/*                    buttonColor={'error'}*/}
            {/*                    buttonIcon={<ErrorOutline />}*/}
            {/*                    alertSeverity={'error'}*/}
            {/*                    shouldBindOnHover={true}*/}
            {/*                    alertContent={*/}
            {/*                        <>*/}
            {/*                            Unable to check SMS checkbox. Please add a phone number in {<LinkToProfile color={'inherit'} />} to enable SMS*/}
            {/*                            notifications.*/}
            {/*                        </>*/}
            {/*                    }*/}
            {/*                    iconButtonSize={'small'}*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*        )}*/}
            {/*    </Grid>*/}
            {/*)}*/}
            {/*{props.notificationChannels.map((channel) => channel.name).includes(NotificationChannelName.InApp) && (*/}
            {/*    <Grid className={cx(classes.ColumnCheckboxMinWidth)} item xs={2}>*/}
            {/*        <Typography>Slack</Typography>*/}
            {/*    </Grid>*/}
            {/*)}*/}
            {/*<Grid className={cx(classes.Divider)} item xs={12}>*/}
            {/*    <Divider variant={'fullWidth'} />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
                {props.notifications.map((preference, index) => (
                    <PreferenceItemTile
                        key={preference.id}
                        isLoading={props.loadingStates[preference.name] ?? false}
                        notification={preference}
                        setLoading={props.setLoading}
                        notificationChannels={props.notificationChannels}
                        isPhoneNumberSet={props.isPhoneNumberSet}
                        updateChannelPreference={props.updateChannelPreference}
                        updateNotificationStatus={props.updateNotificationStatus}
                    />
                    // <PreferenceItem
                    //     key={preference.id}
                    //     isLoading={props.loadingStates[preference.name] ?? false}
                    //     notification={preference}
                    //     setLoading={props.setLoading}
                    //     notificationChannels={props.notificationChannels}
                    //     isPhoneNumberSet={props.isPhoneNumberSet}
                    //     updateChannelPreference={props.updateChannelPreference}
                    //     updateNotificationStatus={props.updateNotificationStatus}
                    // />
                ))}
            </Grid>
        </NotificationSettingsTab>
    );
};

const useStyles = customMakeStyles<INotificationsPreferencesProps>()((theme, props) => ({
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
    },
    ColumnCheckboxMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '90px',
        },
    },
    Divider: { marginBottom: '1rem' },
}));

export { NotificationPreferences };
