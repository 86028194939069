import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Link, LinkOwnProps, LinkProps } from '@mui/material';
import { LinkTypeMap } from '@mui/material/Link/Link';
import { OverridableComponent } from '@mui/material/OverridableComponent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomButtonLinkProps extends LinkProps<'button', { component?: React.ElementType }> {
    buttonText: string;
}

const CustomButtonLink: React.FC<ICustomButtonLinkProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { buttonText, ...linkProps } = props;

    return (
        <Link {...linkProps} className={cx(classes.LinkStyle)} underline={'hover'} type={'button'} component={'button'}>
            {buttonText}
        </Link>
    );
};

const useStyles = customMakeStyles<ICustomButtonLinkProps>()((theme, props) => ({
    LinkStyle: { '&:hover': { color: theme.palette.primary.dark }, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
}));

export { CustomButtonLink };
