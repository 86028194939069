import { useMemo, useState } from 'react';
import { useKeycloak } from '@react-keycloak-fork/web';
import { useQuery } from '@tanstack/react-query';
import { ReportsApi } from '@vegaplatformui/apis';
import { queryKeys } from './query-keys';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseSharedReportsApiHook {
    isLoading: boolean;
    sharedEmbeddedUrl: string;
    dashboardId: string;
    sharedViewId: string | null;
    setDashboardId: React.Dispatch<React.SetStateAction<string>>;
    setSharedViewId: React.Dispatch<React.SetStateAction<string | null>>;
}

export interface IUseSharedReportsApiProps {}

export function useSharedReportsApi(props: IUseSharedReportsApiProps): IUseSharedReportsApiHook {
    const { keycloak } = useKeycloak();
    const [dashboardId, setDashboardId] = useState<string>('');
    const [sharedViewId, setSharedViewId] = useState<string | null>(null);
    const flags = useFlags();

    const reportsApi = useMemo(() => {
        const reportsApi = new ReportsApi();
        reportsApi.keycloak = keycloak;
        return reportsApi;
    }, [keycloak]);

    const {
        data: sharedEmbeddedUrl,
        isError: isSharedEmbeddedUrlError,
        error: sharedEmbeddedUrlError,
        isLoading: isSharedEmbeddedUrlLoading,
    } = useQuery({
        queryKey: [queryKeys.reportsApi.sharedDashboardEmbeddedUrl, dashboardId, sharedViewId],

        queryFn: async () => {
            if (!!dashboardId && !!sharedViewId) {
                const response = await reportsApi.postEmbeddedDashboardURL({
                    dashboardId: dashboardId,
                    isPersistent: false,
                });

                return response.data;
            }
        },
        enabled: flags.sharedReports && sharedViewId !== null && dashboardId !== '',
        gcTime: 0,
        meta: {
            errorMessage: 'There was a problem getting shared embedded Url',
        },
    });

    return {
        isLoading: isSharedEmbeddedUrlLoading,
        sharedEmbeddedUrl: sharedEmbeddedUrl ?? '',
        dashboardId: dashboardId,
        sharedViewId: sharedViewId,
        setDashboardId: setDashboardId,
        setSharedViewId: setSharedViewId,
    };
}
