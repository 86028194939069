import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { IUser, IUserSettingRealmRole } from '@vegaplatformui/models';
import { GridPagination, GridSlotsComponent } from '@mui/x-data-grid';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { CustomGridColStringOperatorDef } from '../../../utilities/custom-grid-col-string-operator-def';
import { Box, Stack, Typography } from '@mui/material';
import { StyledToolTip } from '../../../utilities/styled-tooltip';
import { Link, useParams } from 'react-router-dom';
import { useRouteUrls } from '@vegaplatformui/utils';
import { DataGridCellTooltip } from '../../../utilities/datagrid-cell-tooltip';
import { DataGridCustomToolbar } from '../../../utilities/datagrid-custom-toolbar';
import { CustomDatagridColumnMenu } from '../../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { tableIdentifierKeys } from '../../../use-table-utilities/table-identifier-keys';
import { useTableUtilities } from '../../../use-table-utilities/use-table-utilities';
import { useTheme } from '@mui/material/styles';
import { FormatListBulleted, List, People } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPermissionSettingsTableV2Props {
    isLoading: boolean;
    roles: IUserSettingRealmRole[];
    users: IUser[];
}

const PermissionSettingsTableV2: React.FC<IPermissionSettingsTableV2Props> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const rolesAndPermissionsTableUtilities = useTableUtilities(tableIdentifierKeys.rolesAndPermissionsTableV2);
    const { routeUrls } = useRouteUrls({});
    const { tab } = useParams();

    React.useEffect(() => {
        rolesAndPermissionsTableUtilities.registerTableControl();
    }, []);

    const getPermissionRoute = (id: string, permissionTab?: string) => {
        return permissionTab ? `/${routeUrls.settings.route.path}/${tab}/${id}/${permissionTab}` : `/${routeUrls.settings.route.path}/${tab}/${id}`;
    };

    const roleInformation = (params: GridRenderCellParams<IUserSettingRealmRole>) => {
        return (
            <Stack
                spacing={0}
                direction={'column'}
                justifyContent='center'
                alignItems='flex-start'
                className={cx(classes.TypographyOverflow, classes.RoleNameCell)}
            >
                <Link
                    className={cx(classes.LinkStyle)}
                    tabIndex={params.tabIndex}
                    style={{ color: theme.palette.primary.main }}
                    to={getPermissionRoute(params.row.id)}
                >
                    <Typography variant={'body1'} className={cx(classes.TypographyOverflow, classes.LinkTypography)}>
                        {params.row.name}
                    </Typography>
                </Link>
                <Stack width={'100%'} height={'100%'}>
                    <StyledToolTip arrow placement={'right'} title={params.row.description}>
                        <Typography variant={'caption'} className={cx(classes.TypographyOverflow, classes.DescriptionTypography)}>
                            {params.row.description}
                        </Typography>
                    </StyledToolTip>
                </Stack>
            </Stack>
        );
    };

    const filteredUsersByRole = React.useCallback(
        (role: string) => {
            return (
                props.users.filter((user: IUser) => {
                    return user.platform_roles && user.platform_roles.includes(role);
                }) ?? []
            );
        },
        [props.users]
    );

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'Role',
            flex: 4,
            display: 'flex',
            renderCell: (params: GridRenderCellParams<IUserSettingRealmRole>) => roleInformation(params),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'members',
            headerName: 'Members with this role',
            flex: 1.5,
            display: 'flex',
            valueGetter: (value: string[], row: IUserSettingRealmRole) => filteredUsersByRole(row.name).length,
            renderCell: (params: GridRenderCellParams<IUserSettingRealmRole>) => {
                return (
                    <Link
                        aria-label={`navigate to ${params.row.name}'s members tab`}
                        className={cx(classes.TabLinkStyle)}
                        tabIndex={params.tabIndex}
                        to={getPermissionRoute(params.row.id, 'members')}
                    >
                        <Stack spacing={0.5} direction='row'>
                            <People fontSize={'small'} />
                            <Typography variant={'body2'} color='inherit'>
                                {filteredUsersByRole(params.row.name).length}
                            </Typography>
                        </Stack>
                    </Link>
                );
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'permissions',
            headerName: 'Associated Permissions',
            flex: 1.5,
            display: 'flex',
            valueGetter: (value: string[], row: IUserSettingRealmRole) => row.permissions.length,
            renderCell: (params: GridRenderCellParams<IUserSettingRealmRole>) => (
                <Link
                    aria-label={`navigate to ${params.row.name}'s permissions tab`}
                    className={cx(classes.TabLinkStyle)}
                    tabIndex={params.tabIndex}
                    to={getPermissionRoute(params.row.id, 'permission')}
                >
                    <Stack width={'100%'} height={'100%'} spacing={0.5} direction='row'>
                        <FormatListBulleted fontSize={'small'} />
                        <Typography variant={'body2'} color='inherit'>
                            {params.row.permissions.length}
                        </Typography>
                    </Stack>
                </Link>
            ),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            type: 'actions',
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            disableExport: true,
            display: 'flex',
            flex: 1,
            align: 'center',
            headerName: 'Actions',
            renderCell: (params: GridRenderCellParams<IUserSettingRealmRole>) => (
                <Link
                    className={cx(classes.LinkStyle)}
                    tabIndex={params.tabIndex}
                    style={{ color: theme.palette.primary.main }}
                    to={getPermissionRoute(params.row.id)}
                >
                    <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                        <Typography variant={'body2'} color='inherit'>
                            Details
                        </Typography>
                    </Stack>
                </Link>
            ),
        },
    ];

    columns.map((column) => {
        if (!column.renderCell) column.renderCell = DataGridCellTooltip;
        return column;
    });

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    };

    return (
        <Box height={'auto'} style={{ display: 'flex', flexDirection: 'column' }}>
            <DataGridPremium
                className={props.roles.length > 0 ? undefined : cx(commonStyles.classes.MuiDataGridMinHeightLoader)}
                loading={props.isLoading}
                columns={columns}
                rows={props.roles}
                //This renders the checkboxes
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                    pagination: GridPagination,
                }}
                slotProps={{
                    toolbar: { tableIdentifier: tableIdentifierKeys.rolesAndPermissionsTableV2 },
                    columnsManagement: {
                        getTogglableColumns,
                    },
                }}
                density={rolesAndPermissionsTableUtilities.currentTableControl?.density ?? 'standard'}
                onDensityChange={rolesAndPermissionsTableUtilities.onDensityChange}
                pagination={true}
                paginationModel={rolesAndPermissionsTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={rolesAndPermissionsTableUtilities.onPaginationModelChange}
                columnVisibilityModel={rolesAndPermissionsTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={rolesAndPermissionsTableUtilities.onColumnVisibilityModelChange}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<IPermissionSettingsTableV2Props>()((theme, props) => ({
    RoleNameCell: { paddingLeft: '0.5rem', paddingTop: '0.375rem', paddingBottom: '0.375rem' },
    TypographyOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    TabLinkStyle: {
        color: theme.palette.grey[700],
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline', color: theme.palette.primary.main },
    },
    LinkStyle: { textDecoration: 'none', '&:hover': { textDecoration: 'underline', color: theme.palette.primary.main } },
    LinkTypography: { fontWeight: theme.typography.fontWeightMedium },
    DescriptionTypography: { color: theme.palette.grey[500] },
}));

export { PermissionSettingsTableV2 };
