import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Switch, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ITimeZone } from '@vegaplatformui/utils';
import { IParkingScheduleSummary } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChangeScheduleStatusNonScheduledProps {
    onClose: () => void;
    onChangeScheduleStatuses: (
        schedules: IParkingScheduleSummary[],
        isEnabled: boolean,
        disableStartDateTime?: Date,
        enableStartDateTime?: Date,
        timeZone?: ITimeZone
    ) => void;
    schedulesToChangeStatusOn: IParkingScheduleSummary[];
}

const ChangeScheduleStatusNonScheduledContent: React.FC<IChangeScheduleStatusNonScheduledProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [enabled, setIsEnabled] = React.useState<boolean>(true);

    return (
        <>
            <DialogTitle className={classes.GridTitle}>
                <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid xs={11} item>
                        <Typography fontWeight={500}>
                            {props.schedulesToChangeStatusOn.length === 1
                                ? `Do you want to ${props.schedulesToChangeStatusOn[0]?.is_enabled ? `deactivate ` : 'activate '}`
                                : props.schedulesToChangeStatusOn.length > 1 &&
                                  `Do you want to ${enabled ? 'activate' : 'deactivate'} ${props.schedulesToChangeStatusOn.length} schedules`}
                            {props.schedulesToChangeStatusOn.length === 1 && props.schedulesToChangeStatusOn[0]?.name}?
                        </Typography>
                    </Grid>
                    <Grid xs={1} item container justifyContent={'flex-end'}>
                        <IconButton className={cx(classes.CancelButton)} size={'small'} onClick={props.onClose}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.GridContent}>
                {props.schedulesToChangeStatusOn.length === 1 ? (
                    <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                        This will mean the schedule will
                        {props.schedulesToChangeStatusOn[0]?.is_enabled ? ' not ' : ' '}
                        execute during the scheduled times.
                    </DialogContentText>
                ) : (
                    <>
                        <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={0}>
                            <Typography>Inactive</Typography>
                            <Switch onChange={() => setIsEnabled(!enabled)} checked={enabled} />
                            <Typography>Active</Typography>
                        </Stack>
                        <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                            This will mean the selected schedules will
                            {!enabled ? ' not ' : ' '}
                            execute during the scheduled times.
                        </DialogContentText>
                    </>
                )}
            </DialogContent>
            <DialogActions className={cx(classes.GridActions)}>
                <Button variant={'cancel'} onClick={props.onClose} autoFocus>
                    Cancel
                </Button>
                <Button
                    onClick={() =>
                        props.onChangeScheduleStatuses(
                            props.schedulesToChangeStatusOn,
                            props.schedulesToChangeStatusOn.length === 1 ? !props.schedulesToChangeStatusOn[0].is_enabled : enabled
                        )
                    }
                >
                    Confirm
                </Button>
            </DialogActions>
        </>
    );
};

const useStyles = customMakeStyles<IChangeScheduleStatusNonScheduledProps>()((theme, props) => ({
    GridTitle: { padding: '1.5rem', paddingBottom: '1rem' },
    GridContent: { padding: '1.5rem', paddingTop: 0 },
    GridActions: { padding: '1.5rem', paddingTop: 0 },
    CancelButton: { padding: 0 },
}));

export { ChangeScheduleStatusNonScheduledContent };
