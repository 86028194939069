import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { QuicksightReport } from '../quicksight-reports/quicksight-report';
import { QuicksightReportLoadingSkeleton } from '../quicksight-reports/quicksight-report-loading-skeleton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISharedReportsCardProps {
    dashboardUrl: string;
    dashboardId: string;
    viewId: string | null;
    isLoading: boolean;
}

const SharedReports: React.FC<ISharedReportsCardProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return props.isLoading ? (
        <QuicksightReportLoadingSkeleton />
    ) : (
        <QuicksightReport viewId={props.viewId} dashboardUrl={props.dashboardUrl} dashboardId={props.dashboardId} isTabbed={false} />
    );
};

const useStyles = customMakeStyles<ISharedReportsCardProps>()((theme, props) => ({}));

export { SharedReports };
