import { ThemeProvider } from '@mui/material/styles';
import { customMakeStyles } from '@vegaplatformui/styling';
import { CssBaseline, PaletteMode } from '@mui/material';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { themeState } from '../recoil/atom';
import {
    AuthUserRoles,
    CustomColorsState,
    CustomFaviconState,
    OrganizationId,
    OrganizationLoading,
    queryKeys,
    ShowUpdatedUi,
    UserId,
} from '@vegaplatformui/sharedcomponents';
import { LicenseInfo } from '@mui/x-license';
import * as process from 'process';
import { useKeycloak } from '@react-keycloak-fork/web';
import { useOrganizationsApi } from '@vegaplatformui/sharedcomponents';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useGeminiTheme from '../styling/use-gemini-theme';
import { LinearIndeterminateLoadingPageController } from '../components/loading-pages/linear-indeterminate-loading-page-controller';
import { App } from './app';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { ReportsApi, UserSettingApi } from '@vegaplatformui/apis';
import mixpanel from 'mixpanel-browser';
import { sessionTexts } from '@vegaplatformui/utils';
import { ShepherdJourneyProvider } from 'react-shepherd';

dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserDataLoadingHandlerProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const ColorModeContext = createContext({ toggleColorMode: () => {} });
const UserDataLoadingHandler: React.FC<IUserDataLoadingHandlerProps> = () => {
    const [colorMode, setColorMode] = useRecoilState(themeState);
    LicenseInfo.setLicenseKey(process.env.NX_MUI_LICENSE || ' ');
    const { keycloak } = useKeycloak();
    const domainSlug = window.location.hostname.split('.')[0];
    const organizationsApi = useOrganizationsApi({});
    const customColors = useRecoilValue(CustomColorsState);
    const [isPrefetching, setIsPrefetching] = useState(true);
    const [organizationId, setOrganizationId] = useRecoilState(OrganizationId);
    const customFavicon = useRecoilValue(CustomFaviconState);
    const [userId, setUserId] = useRecoilState(UserId);
    const [isOrganizationIDLoading, setIsOrganizationIDLoading] = useRecoilState(OrganizationLoading);
    const setAuthUserRoles = useSetRecoilState(AuthUserRoles);
    const setShowUpdatedUi = useSetRecoilState(ShowUpdatedUi);
    const queryClient = useQueryClient();
    const ldClient = useLDClient();
    const { isUiPreviewOn } = useFlags();
    const colorModeToggle = useMemo(
        () => ({
            // The dark mode switch would invoke this method
            toggleColorMode: () => {
                setColorMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [setColorMode]
    );

    const userSettingApi = useMemo(() => {
        const userSettingApi = new UserSettingApi();
        userSettingApi.keycloak = keycloak;
        return userSettingApi;
    }, [keycloak]);

    const reportsApi = useMemo(() => {
        const reportsApi = new ReportsApi();
        reportsApi.keycloak = keycloak;
        return reportsApi;
    }, [keycloak]);

    const { data: userData } = useQuery({
        queryKey: [queryKeys.appUserDataLoading.userDataLoading, keycloak && keycloak.tokenParsed],
        queryFn: async ({ signal }) => {
            if (keycloak?.tokenParsed?.preferred_username) {
                if (organizationId === '') {
                    setIsOrganizationIDLoading(true);
                }
                const response = await userSettingApi.getUser({ username: keycloak.tokenParsed?.preferred_username }, signal);
                organizationId !== response.data.organization_id && setOrganizationId(response.data.organization_id ?? '');
                userId !== response.data.id && setUserId(response.data.id ?? undefined);
                setIsOrganizationIDLoading(false);
                return response.data;
            }
        },
        retry: (failureCount, error) => {
            return error.message.includes('not found') && failureCount < 3;
        },
        enabled: keycloak?.tokenParsed !== undefined,
        meta: {
            errorMessage: 'There was a problem getting user info',
        },
    });

    useEffect(() => {
        if (keycloak?.tokenParsed?.keycloak_userid && keycloak?.tokenParsed?.email && keycloak?.tokenParsed?.realm_access?.roles) {
            const context = ldClient?.getContext();
            const mappedRoleArray = (keycloak.tokenParsed.mapped_roles && JSON.parse(keycloak.tokenParsed.mapped_roles)) ?? [];
            setAuthUserRoles([...new Set([...keycloak.tokenParsed.realm_access.roles, ...mappedRoleArray])]);
            context?.name !== keycloak.tokenParsed.email &&
                ldClient?.identify({
                    name: keycloak.tokenParsed.email,
                    key: keycloak.tokenParsed.keycloak_userid,
                    custom: {
                        domainSlug: domainSlug,
                        roles: [...new Set([...keycloak.tokenParsed.realm_access.roles, ...mappedRoleArray])],
                        email: keycloak.tokenParsed.email,
                    },
                });
        }
    }, [keycloak.tokenParsed]);

    useEffect(() => {
        setShowUpdatedUi(true);
        const isDev = process.env?.NX_ENVIRONMENT === 'dev';
        if (
            (process.env?.NX_ENVIRONMENT === 'prod' && process.env.NX_MIXPANEL_PROJECT_TOKEN) ||
            (process.env.NX_USE_MIXPANEL && process.env.NX_MIXPANEL_PROJECT_TOKEN)
        ) {
            mixpanel.init(process.env.NX_MIXPANEL_PROJECT_TOKEN, {
                debug: isDev ?? false,
                track_pageview: 'url-with-path-and-query-string',
                persistence: 'localStorage',
            });
        }
    }, []);

    useEffect(() => {
        if (process.env.NX_ENVIRONMENT === 'prod') {
            if (userData && userData.id !== undefined) {
                mixpanel.identify(userData.id);
            }
            if (keycloak?.tokenParsed && keycloak?.tokenParsed.email) {
                mixpanel.people.set_once({ Email: keycloak.tokenParsed.email });
                mixpanel.people.set_once({ $email: keycloak.tokenParsed.email });
                mixpanel.people.set_once({ $name: `${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}` });
                mixpanel.people.set_once({ Realm: domainSlug });
            }
        }
    }, [userData, keycloak.tokenParsed]);

    useEffect(() => {
        if (keycloak.authenticated) {
            sessionStorage.setItem(sessionTexts.keycloakUserId, keycloak.tokenParsed?.keycloak_userid);
            setIsPrefetching(true);
            prefetchData().then(() => {
                setIsPrefetching(false);
            });
        }
    }, [keycloak.authenticated]);

    useEffect(() => {
        if (!!customFavicon.url) {
            const faviconUpdate = async () => {
                //grab favicon element by ID
                const favicon = document.getElementById('favicon');
                //check if element and org logo data exist
                if (favicon && customFavicon.url) {
                    favicon.setAttribute('href', customFavicon.url);
                }
            };
            //run our function here
            faviconUpdate();
        }
    }, [customFavicon]);

    const prefetchData = async () => {
        //Prefetch all the dashboards and store in React Query
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['executivekpis']],
            queryFn: () => getDashboards(['executivekpis']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['navigator']],
            queryFn: () => getDashboards(['navigator']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['anomalies']],
            queryFn: () => getDashboards(['anomalies']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['forecasting']],
            queryFn: () => getDashboards(['forecasting']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['recommendations']],
            queryFn: () => getDashboards(['recommendations']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['committeduse_aws']],
            queryFn: () => getDashboards(['committeduse_aws']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['committeduse_azure']],
            queryFn: () => getDashboards(['committeduse_azure']),
        });
        await queryClient.prefetchQuery({
            queryKey: [queryKeys.reportsApi.dashboards, ['committeduse_gcp']],
            queryFn: () => getDashboards(['committeduse_gcp']),
        });
    };

    const getDashboards = async (folderNames: string[]) => {
        const response = await reportsApi.listDashboards({ folderNames: folderNames });

        return response.data;
    };

    return (
        <ColorModeContext.Provider value={colorModeToggle}>
            <ThemeProvider theme={useGeminiTheme(colorMode, customColors.primary_color, customColors.secondary_color).theme}>
                <ShepherdJourneyProvider>
                    <CssBaseline />
                    {!keycloak.authenticated || isOrganizationIDLoading ? <LinearIndeterminateLoadingPageController /> : <App />}
                </ShepherdJourneyProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

const useStyles = customMakeStyles()((theme) => ({
    listItem: {
        display: 'list-item',
        padding: 0,
    },
    variantError: {
        backgroundColor: theme.palette.error.main,
    },
    variantInfo: {
        backgroundColor: '#E5FFF3',
        color: '#343434',
    },
}));

export { UserDataLoadingHandler };
