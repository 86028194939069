import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Link, Stack, Typography } from '@mui/material';
import { IResourceBusinessGroup } from '@vegaplatformui/models';
import { StyledToolTip } from './styled-tooltip';
import { CustomButtonLink } from './custom-button-link';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IExpandableBusinessGroupingCellProps {
    businessGroupings: IResourceBusinessGroup[];
    tabIndex: 0 | -1;
    limit: number;
}

const ExpandableBusinessGroupingCell: React.FC<IExpandableBusinessGroupingCellProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { businessGroupings, tabIndex, limit } = props;
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Stack direction={'column'} spacing={1}>
            {expanded
                ? businessGroupings.map((bg: IResourceBusinessGroup) => {
                      return (
                          <StyledToolTip key={bg.id} arrow title={bg.name} placement='left'>
                              <Typography key={bg.id} className={cx(classes.BusinessGroupingTypography)} variant={'body2'}>
                                  {bg.name}
                              </Typography>
                          </StyledToolTip>
                      );
                  })
                : businessGroupings.slice(0, limit).map((bg: IResourceBusinessGroup) => {
                      return (
                          <StyledToolTip key={bg.id} arrow title={bg.name} placement='left'>
                              <Typography key={bg.id} className={cx(classes.BusinessGroupingTypography)} variant={'body2'}>
                                  {bg.name}
                              </Typography>
                          </StyledToolTip>
                      );
                  })}
            {businessGroupings.length > limit && (
                <Typography variant={'body2'} align={'left'}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                    <CustomButtonLink
                        buttonText={expanded ? 'Show less' : `+ ${businessGroupings.length - limit} more`}
                        tabIndex={tabIndex}
                        onClick={() => setExpanded(!expanded)}
                    />
                </Typography>
            )}
        </Stack>
    );
};

const useStyles = customMakeStyles<IExpandableBusinessGroupingCellProps>()((theme, props) => ({
    LinkStyle: { textDecoration: 'none', '&:hover': { textDecoration: 'underline', color: theme.palette.primary.main } },
    BusinessGroupingTypography: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));

export { ExpandableBusinessGroupingCell };
