import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { Avatar, Box, Checkbox, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useKeycloak } from '@react-keycloak-fork/web';
import process from 'process';
import mixpanel from 'mixpanel-browser';
import { sessionTexts, useMenuItems, useRouteUrls } from '@vegaplatformui/utils';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { chainEventHandlers } from 'material-ui-popup-state/chainEventHandlers';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { ShowUpdatedUi } from '../recoil/atom';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomAppbarProfileMenuProps {
    setAuthenticated: SetterOrUpdater<boolean>;
    logoutUrl: string;
    selectedMenuItem: GeminiMenuItem;
    onNavigateAppBarButton: (menuItem: GeminiMenuItem, tabId?: string) => void;
    photoUrl?: string;
}

const CustomAppbarProfileMenu: React.FC<ICustomAppbarProfileMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const flags = useFlags();
    const { keycloak } = useKeycloak();
    const { routeUrls } = useRouteUrls({});
    const { menuItems } = useMenuItems({});
    const [isTransferFilesCollapseOpen, setIsTransferFilesCollapseOpen] = React.useState(false);

    const theme = useTheme();

    const onLogoutClicked = () => {
        if (
            (process.env?.NX_ENVIRONMENT === 'prod' && process.env.NX_MIXPANEL_PROJECT_TOKEN) ||
            (process.env.NX_USE_MIXPANEL && process.env.NX_MIXPANEL_PROJECT_TOKEN)
        ) {
            mixpanel.reset();
        }
        keycloak.logout();
        localStorage.removeItem(sessionTexts.authenticated);
        props.setAuthenticated(false);
        localStorage.setItem(sessionTexts.route, props.logoutUrl);
    };

    const onNavigateClicked = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, menuItem: GeminiMenuItem, tab?: string) => {
        event.stopPropagation();
        props.onNavigateAppBarButton(menuItem, tab);
    };

    const profileAvatar = () => {
        return (
            <Avatar className={cx(classes.Avatar)} src={props.photoUrl} alt={'profile-avatar'}>
                <Typography>
                    {keycloak.tokenParsed?.given_name.charAt(0).toUpperCase()}
                    {keycloak.tokenParsed?.family_name.charAt(0).toUpperCase()}
                </Typography>
            </Avatar>
        );
    };

    return (
        <PopupState variant='popover' popupId={'profile-menu-popover'} parentPopupState={undefined} disableAutoFocus={undefined}>
            {(popupState) => {
                const onClose = () => {
                    setIsTransferFilesCollapseOpen(false);
                    popupState.close();
                };
                return (
                    <>
                        <StyledToolTip arrow={true} title={'Profile Menu'}>
                            <IconButton
                                className={cx(classes.ButtonAvatar)}
                                id={'profile-menu-button'}
                                sx={{
                                    backgroundColor: popupState.isOpen
                                        ? `${theme.palette.primary.main}70`
                                        : props.selectedMenuItem?.route === routeUrls.fileDownloads.path ||
                                            props.selectedMenuItem?.route === routeUrls.fileTransfer.path ||
                                            props.selectedMenuItem?.route === routeUrls.cloudHeroSummaries.path ||
                                            props.selectedMenuItem?.route === routeUrls.sharedReport.path
                                          ? `${theme.palette.primary.main}`
                                          : 'transparent',
                                }}
                                size={'small'}
                                aria-label={'open profile menu'}
                                aria-owns={popupState.isOpen ? 'profile-menu' : undefined}
                                {...bindTrigger(popupState)}
                            >
                                {profileAvatar()}
                            </IconButton>
                        </StyledToolTip>
                        <Menu
                            id={'profile-menu'}
                            className={cx(classes.Menu)}
                            {...bindMenu(popupState)}
                            {...chainEventHandlers(bindMenu(popupState), {
                                onClose: (e: React.MouseEvent) => {
                                    onClose();
                                },
                            })}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Stack component={'template'} className={cx(classes.InformationStack)} direction={'row'} spacing={1}>
                                {profileAvatar()}
                                <Stack className={cx(classes.HeaderTypography)} direction={'column'} spacing={0.5}>
                                    <Typography className={cx(classes.HeaderTypography)} variant={'caption'} noWrap>
                                        {keycloak.tokenParsed?.given_name} {keycloak.tokenParsed?.family_name}
                                    </Typography>
                                    <Typography className={cx(classes.EmailTypography, classes.HeaderTypography)} variant={'caption'} noWrap>
                                        {keycloak.tokenParsed?.email}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Divider />
                            <MenuItem
                                onClick={(event) => {
                                    event.preventDefault();
                                    onClose();
                                    const menuItem = menuItems.find((item) => item.route === routeUrls.settings.route.path);
                                    menuItem && onNavigateClicked(event, menuItem, 'profile');
                                }}
                                component={'a'}
                                href={`${routeUrls.settings.route.path}`}
                            >
                                Profile Settings
                            </MenuItem>
                            <MenuItem
                                autoFocus={popupState.isOpen}
                                selected={
                                    props.selectedMenuItem?.route === routeUrls.fileTransfer.path ||
                                    props.selectedMenuItem?.route === routeUrls.fileDownloads.path
                                }
                                key={'transfer-files-parent'}
                                onClick={() => setIsTransferFilesCollapseOpen(!isTransferFilesCollapseOpen)}
                            >
                                <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography variant={'body2'}>Transfer Files</Typography>
                                    {
                                        <ListItemIcon className={cx(classes.ListItemIcon)}>
                                            {isTransferFilesCollapseOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}{' '}
                                        </ListItemIcon>
                                    }
                                </Stack>
                            </MenuItem>
                            {isTransferFilesCollapseOpen && [
                                <MenuItem
                                    key={'file-download'}
                                    className={cx(classes.SubMenuItem)}
                                    href={`${routeUrls.fileDownloads.path}`}
                                    component={'a'}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onClose();
                                        const subMenuItem = menuItems
                                            .find((item) => item.route === routeUrls.myFiles.path)
                                            ?.subMenu?.find((subItem) => subItem.route === routeUrls.fileDownloads.path);
                                        subMenuItem && onNavigateClicked(event, subMenuItem);
                                    }}
                                    selected={props.selectedMenuItem?.route === routeUrls.fileDownloads.path}
                                >
                                    From Vega
                                </MenuItem>,
                                <MenuItem
                                    key={'file-transfer'}
                                    className={cx(classes.SubMenuItem)}
                                    href={`${routeUrls.fileTransfer.path}`}
                                    component={'a'}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onClose();
                                        const subMenuItem = menuItems
                                            .find((item) => item.route === routeUrls.myFiles.path)
                                            ?.subMenu?.find((subItem) => subItem.route === routeUrls.fileTransfer.path);
                                        subMenuItem && onNavigateClicked(event, subMenuItem);
                                    }}
                                    selected={props.selectedMenuItem?.route === routeUrls.fileTransfer.path}
                                >
                                    To Vega
                                </MenuItem>,
                            ]}
                            <MenuItem
                                onClick={(event) => {
                                    event.preventDefault();
                                    onClose();
                                    const menuItem = menuItems.find((item) => item.route === routeUrls.cloudHeroSummaries.path);
                                    menuItem && onNavigateClicked(event, menuItem);
                                }}
                                component={'a'}
                                href={`${routeUrls.cloudHeroSummaries.path}`}
                                selected={props.selectedMenuItem?.route === routeUrls.cloudHeroSummaries.path}
                            >
                                Cloud Hero
                            </MenuItem>
                            {flags.sharedReports && (
                                <MenuItem
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onClose();
                                        const menuItem = menuItems.find((item) => item.route === routeUrls.sharedReport.path);
                                        menuItem && onNavigateClicked(event, menuItem);
                                    }}
                                    component={'a'}
                                    href={`${routeUrls.sharedReport.path}`}
                                    selected={props.selectedMenuItem?.route === routeUrls.sharedReport.path}
                                >
                                    Shared Report Viewer
                                </MenuItem>
                            )}
                            <Divider sx={{ margin: `0 !important` }} />
                            <MenuItem
                                onClick={() => {
                                    onClose();
                                    onLogoutClicked();
                                }}
                            >
                                Log out
                            </MenuItem>
                        </Menu>
                    </>
                );
            }}
        </PopupState>
    );
};

const useStyles = customMakeStyles<ICustomAppbarProfileMenuProps>()((theme, props) => ({
    Avatar: {
        width: 40,
        height: 40,
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.getContrastText(theme.palette.grey[100]),
        opacity: 'inherit',
    },
    ButtonAvatar: {
        padding: '3px',
        '&:hover': { opacity: '90%', backgroundColor: `${theme.palette.primary.main}50` },
    },
    ListItemIcon: { minWidth: '0px !important' },
    Menu: { maxWidth: 250 },
    InformationStack: { paddingLeft: '16px', paddingRight: '16px', paddingBottom: '4px' },
    HeaderTypography: { textOverflow: 'ellipsis', overflow: 'hidden' },
    EmailTypography: { color: theme.palette.text.secondary },
    SubMenuItem: {
        '&.Mui-selected': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            '&:hover': { color: `${theme.palette.primary.dark} !important` },
        },
        '&:hover': { backgroundColor: `${theme.palette.primary.main}30 !important`, color: `${theme.palette.primary.dark} !important` },
    },
    MenuItemCheckbox: { width: '20px', height: '20px' },
}));

export { CustomAppbarProfileMenu };
