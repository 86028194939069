import { useKeycloak } from '@react-keycloak-fork/web';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SnackbarErrorOutput } from '../../utilities/snackbar-error-output';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { UserId } from '../../recoil/atom';
import { SnackBarOptions } from '../../recoil/atom';
import { INotificationChannel, IUpdateNotificationStatusHook, IUpsertNotificationPreferenceHook, IVegaNotification } from '@vegaplatformui/models';
import { useMemo, useState } from 'react';
import { NotificationsAPI } from '@vegaplatformui/apis';
import { queryKeys } from '../query-keys';
import { getMappedVegaNotificationData } from '@vegaplatformui/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IUseNotificationPreferencesApiHook {
    updateChannelPreference: (hookRequest: IUpsertNotificationPreferenceHook) => void;
    updateNotificationStatus: (hookRequest: IUpdateNotificationStatusHook) => void;
    notificationChannels: INotificationChannel[];
    vegaNotifications: IVegaNotification[];
    isVegaNotificationsLoading: boolean;
    isInitialNotificationsLoading: boolean;
    loadingStates: Record<string, boolean>;
    setLoading: (id: string, isLoading: boolean) => void;
}

export interface IUseNotificationPreferencesApiProps {}

export function useNotificationPreferencesApi(props: IUseNotificationPreferencesApiProps): IUseNotificationPreferencesApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});
    const userId = useRecoilValue(UserId);
    const queryClient = useQueryClient();
    const flags = useFlags();

    const setLoading = (id: string, isLoading: boolean) => {
        setLoadingStates((prevState) => ({
            ...prevState,
            [id]: isLoading,
        }));
    };

    const notificationsApi = useMemo(() => {
        const apiInstance = new NotificationsAPI();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const { data: notificationConfigurations, isLoading: isNotificationConfigurationsLoading } = useQuery({
        queryKey: [queryKeys.notificationTabs.getConfigurations, userId],
        queryFn: async ({ signal }) => {
            if (userId) {
                await queryClient.cancelQueries({
                    queryKey: [queryKeys.notificationTabs.getConfigurations],
                });
                const response = await notificationsApi.getNotificationConfigurations(userId, signal);
                if (response.data.data === null) return [];
                return response.data.data;
            }
        },
        enabled: !!userId && flags.notificationService,
        meta: {
            errorMessage: 'Unable to get user notification configurations',
        },
    });

    const { data: notificationTypes, isLoading: isNotificationTypesLoading } = useQuery({
        queryKey: [queryKeys.notificationTabs.getNotificationTypes],
        queryFn: async ({ signal }) => {
            await queryClient.cancelQueries({
                queryKey: [queryKeys.notificationTabs.getNotificationTypes],
            });
            const response = await notificationsApi.getNotificationTypes(signal);
            if (response.data.data === null) return [];
            return response.data.data;
        },
        enabled: flags.notificationService && window.location.pathname.includes('notification'),
        meta: {
            errorMessage: 'Unable to get user notification types',
        },
    });
    const { data: notificationChannels, isLoading: isNotificationChannelsLoading } = useQuery({
        queryKey: [queryKeys.notificationTabs.getNotificationChannels],
        queryFn: async ({ signal }) => {
            await queryClient.cancelQueries({
                queryKey: [queryKeys.notificationTabs.getNotificationChannels],
            });
            const response = await notificationsApi.getNotificationChannels(signal);
            if (response.data.data === null) return [];
            return response.data.data;
        },
        enabled: flags.notificationService && window.location.pathname.includes('notification'),
        meta: {
            errorMessage: 'Unable to get user notification channels',
        },
    });

    const { data: vegaNotifications, isLoading: isVegaNotificationsLoading } = useQuery({
        queryKey: [queryKeys.notificationTabs.getNotifications, notificationTypes, notificationChannels, notificationConfigurations],
        queryFn: async () => {
            if (!!notificationTypes && !!notificationChannels && !!notificationConfigurations) {
                return getMappedVegaNotificationData(notificationTypes, notificationConfigurations, notificationChannels);
            }
        },
        enabled:
            flags.notificationService &&
            window.location.pathname.includes('notification') &&
            !!notificationTypes &&
            !!notificationConfigurations &&
            !!notificationChannels,
        meta: {
            errorMessage: 'Unable to get notifications list',
        },
    });

    const { mutate: updateChannelPreference } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem changing this preference:  ${SnackbarErrorOutput(error ?? '')}`,
            });
            queryClient.setQueryData([queryKeys.notificationTabs.getConfigurations, userId], notificationConfigurations);
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Preference has been saved',
            });
            const mappedConfigurationIds = notificationConfigurations?.map((config) => config.id);
            if (
                !!response &&
                response.data &&
                response.data.result &&
                notificationConfigurations &&
                !mappedConfigurationIds?.includes(response.data.result.id)
            ) {
                queryClient.setQueryData(
                    [queryKeys.notificationTabs.getConfigurations, userId],
                    [...notificationConfigurations, response.data.result]
                );
            } else if (!!response && response.data && response.data.result && notificationConfigurations) {
                // queryClient.invalidateQueries({ queryKey: [queryKeys.notificationTabs.getConfigurations] });
                queryClient.setQueryData(
                    [queryKeys.notificationTabs.getConfigurations, userId],
                    notificationConfigurations.map((config) =>
                        config.notification_type_id === response.data.result.notification_type_id &&
                        config.notification_channel_id === response.data.result.notification_channel_id
                            ? response.data.result
                            : config
                    )
                );
            } else {
                queryClient.invalidateQueries({ queryKey: [queryKeys.notificationTabs.getConfigurations] });
            }
        },
        onSettled: async (response, error, variables, context) => {
            setLoading(variables.notification_type_name, false);
        },
        mutationFn: async (hookRequest: IUpsertNotificationPreferenceHook) => {
            if (flags.notificationService && userId) {
                return await notificationsApi.postNotificationConfiguration({
                    user_id: userId,
                    notification_channel: hookRequest.notification_channel_name,
                    notification_type: hookRequest.notification_type_name,
                    is_enabled: hookRequest.is_enabled,
                    settings: hookRequest.settings,
                });
            }
        },
    });
    // TODO: re add API integration once the knock port requires a higher level on/off
    // Keeping the mutation since I don't want to remove the entire pipeline
    const { isPending: isUpdateNotificationStatusLoading, mutate: updateNotificationStatus } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem changing the notification status:  ${SnackbarErrorOutput(error ?? '')}`,
            });
            queryClient.setQueryData([queryKeys.notificationTabs.getConfigurations, userId], notificationConfigurations);
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Notification status has been saved',
            });
            if (!!response && response.data && notificationConfigurations) {
                // Spread unaffected configs and net new configs/ones that might have changed
                queryClient.setQueryData(
                    [queryKeys.notificationTabs.getConfigurations, userId],
                    [
                        ...notificationConfigurations.filter((config) => !response.data.map((item: any) => item.id).includes(config.id)),
                        ...response.data,
                    ]
                );
            } else {
                queryClient.invalidateQueries({ queryKey: [queryKeys.notificationTabs.getConfigurations] });
            }
        },
        onSettled: (response, error, variables, context) => {
            // TODO: When using again make sure to use notification (type) name
            setLoading(variables.notification_type_id, false);
        },
        mutationFn: async (hookRequest: IUpdateNotificationStatusHook) => {
            if (flags.notificationService) {
                console.log('Not Implemented To Endpoint');
                const data: any = {};
                return { data };
            }
        },
    });

    return {
        updateChannelPreference,
        updateNotificationStatus,
        vegaNotifications: vegaNotifications ?? [],
        isVegaNotificationsLoading:
            isVegaNotificationsLoading || isNotificationChannelsLoading || isNotificationConfigurationsLoading || isNotificationTypesLoading,
        isInitialNotificationsLoading:
            vegaNotifications === undefined &&
            (notificationChannels === undefined || notificationConfigurations === undefined || notificationTypes === undefined),
        notificationChannels: notificationChannels ?? [],
        setLoading,
        loadingStates,
    };
}
