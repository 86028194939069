import { ContainerType, IVegaContainer } from '@vegaplatformui/models';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import React from 'react';
import { GridRowId } from '@mui/x-data-grid';
import { Box, ButtonProps, IconButton, Stack, StackProps } from '@mui/material';
import { AutoAwesomeMotion, FolderSharp, GroupWork, Inventory2, KeyboardArrowDownRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { CustomButtonLink } from './custom-button-link';
import { GridTreeNodeWithRender, gridClasses } from '@mui/x-data-grid-premium';
import { useTheme } from '@mui/material/styles';

export interface IGetDescendantCountProps {
    row: IVegaContainer;
    tabIndex: number;
    apiRef: React.MutableRefObject<GridApiPremium>;
    field: string;
    rowNode: GridTreeNodeWithRender;
    spaces: IVegaContainer[];
    onClickTableItem: (container: IVegaContainer[], containerType?: ContainerType) => void;
}

const GetVegaContainerDescendantCount: React.FC<IGetDescendantCountProps> = (props) => {
    const { cx, classes } = useStyles(props);
    const theme = useTheme();
    const rows = props.apiRef.current.getRowGroupChildren({ groupId: props.row.id });
    const [isExpanded, setIsExpanded] = React.useState(false);

    const handleClick: ButtonProps['onClick'] = (event) => {
        if (props.rowNode.type !== 'group') {
            return;
        }

        props.apiRef.current.setRowChildrenExpansion(props.row.id, !props.rowNode.childrenExpanded);
        setIsExpanded(!isExpanded);
        props.apiRef.current.setCellFocus(props.row.id, props.field);
        event.stopPropagation();
    };

    const handleKeyDown: StackProps['onKeyDown'] = (event) => {
        if (props.rowNode.type !== 'group' || event.key !== 'Space') {
            return;
        }

        props.apiRef.current.setRowChildrenExpansion(props.row.id, !props.rowNode.childrenExpanded);
        setIsExpanded(!isExpanded);
        props.apiRef.current.setCellFocus(props.row.id, props.field);
        event.stopPropagation();
    };

    const ContainerNameRender = (id: GridRowId, isEnabled: boolean) => {
        const currentContainer = props.spaces.find((space) => space.id === id);
        const type = currentContainer?.container_type;
        const name = currentContainer?.name;
        const containerId = currentContainer?.id;

        let container: IVegaContainer[];

        switch (type) {
            case ContainerType.Space.toLowerCase():
                container = props.spaces.filter((space) => space.path?.split('/')[0] === containerId);

                break;

            case ContainerType.Workload.toLowerCase():
                container = props.spaces.filter((workload) => workload.path?.split('/')[1] === containerId);

                break;

            case ContainerType.ResourcePool.toLowerCase():
                container = props.spaces.filter((resourcepool) => resourcepool.path?.split('/')[2] === containerId);

                break;
        }
        return (
            <Stack
                onKeyDown={handleKeyDown}
                tabIndex={!isEnabled && type !== ContainerType.Resource.toLowerCase() ? props.tabIndex : -1}
                className={cx(classes.GroupingStack, classes.TitleStack)}
                spacing={0}
                direction={'row'}
                width={'100%'}
                alignItems={'center'}
            >
                <Box className={cx(gridClasses.treeDataGroupingCellToggle)}>
                    {isEnabled && (
                        <IconButton tabIndex={-1} size={'small'} onClick={handleClick}>
                            {'childrenExpanded' in props.rowNode && props.rowNode.childrenExpanded ? (
                                <KeyboardArrowDownRounded fontSize={'small'} />
                            ) : (
                                <KeyboardArrowRightRounded fontSize={'small'} />
                            )}
                        </IconButton>
                    )}
                </Box>
                <Stack className={cx(classes.TitleStack)} direction={'row'} spacing={1}>
                    {type === ContainerType.Space.toLowerCase() ? (
                        <FolderSharp />
                    ) : type === ContainerType.Workload.toLowerCase() ? (
                        <AutoAwesomeMotion />
                    ) : type === ContainerType.ResourcePool.toLowerCase() ? (
                        <GroupWork />
                    ) : type === ContainerType.Resource.toLowerCase() ? (
                        <Inventory2 />
                    ) : (
                        ''
                    )}
                    <CustomButtonLink
                        tabIndex={props.tabIndex}
                        buttonText={name ?? ''}
                        onClick={() => {
                            props.onClickTableItem(container, type);
                        }}
                        sx={{ color: !isEnabled && type !== ContainerType.Resource.toLowerCase() ? theme.palette.grey[400] : undefined }}
                        disabled={!isEnabled && type !== ContainerType.Resource.toLowerCase()}
                    />
                </Stack>
            </Stack>
        );
    };
    return ContainerNameRender(props.row.id!, rows.length > 0);
};

const useStyles = customMakeStyles<IGetDescendantCountProps>()((theme, props) => ({
    ContainerButton: {
        textTransform: 'none',
    },
    GroupingStack: { marginLeft: ((props.rowNode && props.rowNode.depth) ?? 0) * 16 },
    TitleStack: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
}));

export { GetVegaContainerDescendantCount };
