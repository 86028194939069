import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Collapse, ListItem, ListItemButton, ListItemText, ListSubheader, Stack } from '@mui/material';
import { BusinessRounded, Person } from '@mui/icons-material';
import { IRouteUrlTab } from '@vegaplatformui/models';
import { useRouteUrls } from '@vegaplatformui/utils';
import { NumberSize, Resizable, ResizeDirection } from 're-resizable';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISettingsV2MenuProps {
    profileSettingsItems: IRouteUrlTab[];
    organizationSettingsItems: IRouteUrlTab[];
    settingsMenuWidth: number;
    setSettingsMenuWidth: React.Dispatch<React.SetStateAction<number>>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onChangeSettingsLocation: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: IRouteUrlTab) => void;
}

const SettingsV2Menu: React.FC<ISettingsV2MenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { profileSettingsItems, organizationSettingsItems, settingsMenuWidth, setSettingsMenuWidth, isOpen, setIsOpen, onChangeSettingsLocation } =
        props;
    //Routes hook
    const { routeUrls } = useRouteUrls({});

    const maxWidth = 319;
    const minWidth = 75;

    const onSettingsMenuResize = (event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        setSettingsMenuWidth((current) => {
            if (current + delta.width < minWidth) {
                setIsOpen(false);
            }
            return current + delta.width;
        });
    };

    useEffect(() => {
        if (settingsMenuWidth < minWidth && !isOpen) {
            setSettingsMenuWidth(minWidth + 25);
        }
    }, [isOpen]);

    return (
        <Stack id={'settings-menu'} className={cx(classes.MenuStack)} direction='row'>
            <Collapse orientation='horizontal' in={isOpen}>
                <Resizable
                    enable={{
                        top: false,
                        right: true,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                    }}
                    onResizeStop={(event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) =>
                        onSettingsMenuResize(event, direction, elementRef, delta)
                    }
                    handleComponent={{
                        right: <Box height={'100%'} component={'div'} role={'separator'} aria-label={'resize settings menu'} />,
                    }}
                    size={{ width: settingsMenuWidth }}
                    maxWidth={maxWidth}
                    minHeight={'100%'}
                >
                    <Box className={cx(classes.SettingsMenuBox, classes.TextOverflowControl)}>
                        <Stack className={cx(classes.StackList, classes.ProfileSettingsStack)} component={'ul'} direction={'column'} spacing={1.5}>
                            <ListSubheader disableGutters disableSticky>
                                <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={1}>
                                    <Person />
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'subtitle1',
                                            className: cx(classes.SubHeaderText, classes.TextOverflowControl),
                                        }}
                                        primary={'Profile Settings'}
                                    />
                                </Stack>
                            </ListSubheader>
                            <Stack role={'presentation'} component={'template'} direction={'column'} spacing={0}>
                                {profileSettingsItems.map((item, index) => {
                                    return (
                                        <ListItem key={item.id} disableGutters disablePadding className={cx(classes.ListItem)}>
                                            <ListItemButton
                                                href={`/${routeUrls.settings.route.path}/${item.id}`}
                                                onClick={(e) => onChangeSettingsLocation(e, item)}
                                                selected={window.location.pathname.includes(item.id)}
                                                className={cx(classes.ListItemButton)}
                                            >
                                                <ListItemText
                                                    primaryTypographyProps={{ variant: 'body1', className: cx(classes.TextOverflowControl) }}
                                                    primary={item.label}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </Stack>
                        </Stack>
                        {organizationSettingsItems.length > 0 && (
                            <Stack className={cx(classes.StackList)} component={'ul'} direction={'column'} spacing={1.5}>
                                <ListSubheader disableGutters disableSticky>
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={1}>
                                        <BusinessRounded />
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: 'subtitle1',
                                                className: cx(classes.SubHeaderText, classes.TextOverflowControl),
                                            }}
                                            primary={'Organization Settings'}
                                        />
                                    </Stack>
                                </ListSubheader>
                                <Stack role={'presentation'} component={'template'} direction={'column'} spacing={0}>
                                    {organizationSettingsItems.map((item) => {
                                        return (
                                            <ListItem key={item.id} disableGutters disablePadding className={cx(classes.ListItem)}>
                                                <ListItemButton
                                                    selected={window.location.pathname.includes(item.id)}
                                                    href={`/${routeUrls.settings.route.path}/${item.id}`}
                                                    onClick={(e) => onChangeSettingsLocation(e, item)}
                                                    className={cx(classes.ListItemButton)}
                                                >
                                                    <ListItemText
                                                        primaryTypographyProps={{ variant: 'body1', className: cx(classes.TextOverflowControl) }}
                                                        primary={item.label}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        )}
                    </Box>
                </Resizable>
            </Collapse>
        </Stack>
    );
};

const useStyles = customMakeStyles<ISettingsV2MenuProps>()((theme, props) => ({
    TextOverflowControl: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflowX: 'hidden' },
    ProfileSettingsStack: {
        paddingBottom: '20px',
    },
    MenuStack: { borderRight: '1px solid #CECEDF', paddingLeft: '1rem' },
    SettingsMenuBox: {
        backgroundColor: theme.palette.background.paper,
        paddingLeft: 0,
        paddingTop: '1rem',
        paddingBottom: '1rem',
        width: '100%',
    },
    SubHeaderText: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
    },
    StackList: { margin: 0, paddingLeft: 0 },
    ListItem: { paddingRight: 0 },
    ListItemButton: {
        padding: '10px',
        height: '34px',
        '&.Mui-selected': {
            '& .MuiTypography-root': {
                fontWeight: theme.typography.fontWeightBold,
            },
            borderLeft: `4px solid ${theme.palette.primary.main} !important`,
            color: theme.palette.primary.main,
            backgroundColor: 'transparent',
        },
        '&.Mui-focusVisible': { backgroundColor: `${theme.palette.primary.main}40`, border: `2px solid ${theme.palette.primary.main}` },
        '&:hover': { backgroundColor: `${theme.palette.primary.main}30` },
    },
}));

export { SettingsV2Menu };
