import React, { ReactNode } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ChatBubbleOutlineRounded } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotificationIntegrationTileProps extends React.PropsWithChildren {
    integrationName: string;
    customIcon?: ReactNode;
}

const NotificationIntegrationTile: React.FC<INotificationIntegrationTileProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid className={cx(classes.IntegrationTile)} item container xs={12}>
            <Grid item container xs={12}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                    {props.customIcon ?? <ChatBubbleOutlineRounded />}
                    <Typography variant={'h6'}>{props.integrationName}</Typography>
                </Stack>
            </Grid>
            <Grid className={cx(classes.ChildContent)} item container xs={12}>
                {props.children}
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<INotificationIntegrationTileProps>()((theme, props) => ({
    IntegrationTile: { border: '1px solid  #D0D5DD', borderRadius: '8px', padding: '1rem', marginTop: '1rem', minWidth: '168px' },
    ChildContent: { paddingTop: '1rem' },
}));

export { NotificationIntegrationTile };
