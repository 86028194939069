import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Divider, Grid, Skeleton, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotificationSettingTabLoadingSkeletonProps {}

const NotificationSettingTabLoadingSkeleton: React.FC<INotificationSettingTabLoadingSkeletonProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid container item spacing={1}>
            <Grid item xs={12}>
                <Skeleton variant={'rounded'} width={'100%'} height={'25rem'} />
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<INotificationSettingTabLoadingSkeletonProps>()((theme, props) => ({
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
    },
    ColumnCheckboxMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '90px',
        },
    },
    Divider: { marginBottom: '1rem' },
}));

export { NotificationSettingTabLoadingSkeleton };
