import React, { useEffect, useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { BusinessGroupingWizardUsersTable } from './business-grouping-wizard-users-table';
import { IBusinessGrouping, IBusinessGroupingDetailsForm, IUser } from '@vegaplatformui/models';
import { Button, Stack, Typography } from '@mui/material';
import {
    Control,
    FieldErrors,
    UseFormHandleSubmit,
    UseFormRegister,
    UseFormReset,
    UseFormSetValue,
    UseFormTrigger,
    UseFormWatch,
} from 'react-hook-form';
import { BusinessGroupingWizardAddUsersDialog } from './business-grouping-wizard-add-users-dialog';
import { BusinessGroupingWizardChangeOwnerDialog } from './business-grouping-wizard-change-owner-dialog';
import { BusinessGroupingWizardUnassignUsersDialog } from './business-grouping-wizard-unassign-users-dialog';
import { Add, Info } from '@mui/icons-material';
import { StyledToolTip } from '../../../../utilities/styled-tooltip';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardUsersTabProps {
    owner: IUser | undefined;
    businessGroupingToEdit: IBusinessGrouping | undefined;
    availableUsers: IUser[];
    assignedUsers: IUser[];
    isLoading: boolean;
    unassignUsers: (users: IUser[]) => void;
    trigger: UseFormTrigger<IBusinessGroupingDetailsForm>;
    watch: UseFormWatch<IBusinessGroupingDetailsForm>;
    control: Control<IBusinessGroupingDetailsForm>;
    register: UseFormRegister<IBusinessGroupingDetailsForm>;
    reset: UseFormReset<IBusinessGroupingDetailsForm>;
    setValue: UseFormSetValue<IBusinessGroupingDetailsForm>;
    errors: FieldErrors<IBusinessGroupingDetailsForm>;
    handleSubmit: UseFormHandleSubmit<IBusinessGroupingDetailsForm>;
}

const BusinessGroupingWizardUsersTab: React.FC<IBusinessGroupingWizardUsersTabProps> = (props) => {
    const [selectedAssignedUsers, setSelectedAssignedUsers] = useState<IUser[]>([]);
    const [userToUnassign, setUserToUnassign] = useState<IUser | undefined>(undefined);
    const [isChangeOwnerDialogOpen, setIsChangeOwnerDialogOpen] = useState<boolean>(false);
    const [isAddUsersDialogOpen, setIsAddUsersDialogOpen] = useState<boolean>(false);
    const [isUnassignUserDialogOpen, setIsUnassignUserDialogOpen] = useState<boolean>(false);
    const [availableUsers, setAvailableUsers] = useState<IUser[]>(props.availableUsers.filter((user) => !props.assignedUsers.includes(user)));
    const commonStyles = useCommonStyles();

    const closeUnassignUsersDialog = () => {
        setSelectedAssignedUsers([]);
        setUserToUnassign(undefined);
        setIsUnassignUserDialogOpen(false);
    };
    const unassignUsers = (users: IUser[]) => {
        props.setValue(
            'business_grouping_users',
            props.assignedUsers.filter((user) => !users.includes(user)),
            { shouldValidate: true, shouldDirty: true }
        );
        props.trigger().then(() => closeUnassignUsersDialog());
    };

    useEffect(() => {
        setAvailableUsers(props.availableUsers.filter((user) => !props.assignedUsers.map((assignedUser) => assignedUser.id).includes(user.id)));
    }, [props.assignedUsers, props.availableUsers]);

    return (
        <>
            <BusinessGroupingWizardAddUsersDialog
                trigger={props.trigger}
                setValue={props.setValue}
                control={props.control}
                isAddUsersDialogOpen={isAddUsersDialogOpen}
                onCloseDialog={() => setIsAddUsersDialogOpen(false)}
                users={availableUsers}
                watch={props.watch}
            />
            <BusinessGroupingWizardChangeOwnerDialog
                isChangeOwnerDialogOpen={isChangeOwnerDialogOpen}
                onCloseDialog={() => setIsChangeOwnerDialogOpen(false)}
                owner={props.owner}
                assignedUsers={props.assignedUsers}
                control={props.control}
                setValue={props.setValue}
            />
            <BusinessGroupingWizardUnassignUsersDialog
                unassignUsers={unassignUsers}
                usersToUnassign={selectedAssignedUsers.length < 1 && userToUnassign ? [userToUnassign] : selectedAssignedUsers}
                isUnassignUserDialogOpen={isUnassignUserDialogOpen}
                onCloseDialog={closeUnassignUsersDialog}
            />
            <Stack direction={'column'}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack direction='row' justifyContent='center' alignItems='flex-start' spacing={1}>
                        <Stack direction='column' justifyContent='center' alignItems='flex-start'>
                            <Typography variant={'body1'}>Owner and Assigned Users</Typography>
                            <Typography variant={'h6'}>{props.assignedUsers.length}</Typography>
                        </Stack>
                        <StyledToolTip title={'Owner is added into header count and excluded in table count.'} placement={'right'} arrow tabIndex={0}>
                            <Info
                                className={commonStyles.classes.TooltipIcons}
                                titleAccess={'Owner is added into header count and excluded in table count.'}
                            />
                        </StyledToolTip>
                    </Stack>
                    <Stack direction={'row'} spacing={1}>
                        {selectedAssignedUsers.length > 0 && (
                            <Button
                                onClick={() => {
                                    setIsUnassignUserDialogOpen(true);
                                }}
                                color={'error'}
                            >
                                Remove Users
                            </Button>
                        )}
                        <Button startIcon={<Add />} onClick={() => setIsAddUsersDialogOpen(true)} disabled={selectedAssignedUsers.length > 0}>
                            Users
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <BusinessGroupingWizardUsersTable
                setUserToUnassign={setUserToUnassign}
                owner={props.owner}
                businessGroupingToEdit={props.businessGroupingToEdit}
                assignedUsers={props.assignedUsers}
                selectedAssignedUsers={selectedAssignedUsers}
                setSelectedAssignedUsers={setSelectedAssignedUsers}
                onClickUnassignUsers={() => setIsUnassignUserDialogOpen(true)}
                onClickSwitchOwner={() => setIsChangeOwnerDialogOpen(true)}
                isLoading={props.isLoading}
                control={props.control}
                register={props.register}
                setValue={props.setValue}
                errors={props.errors}
                reset={props.reset}
            />
        </>
    );
};

export { BusinessGroupingWizardUsersTab };
