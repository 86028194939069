import React, { useEffect, useMemo, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Icon, Stack, TextField, Typography } from '@mui/material';
import { NotificationIntegrationTile } from './notification-integration-tile';
import { useTheme } from '@mui/material/styles';
import { ErrorOutline } from '@mui/icons-material';
import { FormField } from '../../../forms';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    IOrganizationConfigurationForm,
    IOrganizationNotificationConfiguration,
    IPostOrganizationIntegrationRequest,
    IPutOrganizationIntegrationRequest,
    NotificationChannelName,
    NotificationIntegrationTypeType,
} from '@vegaplatformui/models';
import { LoadingButton } from '@mui/lab';
import { FormFieldLoadingSkeleton } from '../../../utilities/form-field-loading-skeleton';
import { MicrosoftTeams, Slack } from '@vegaplatformui/sharedassets';
import { useKeycloak } from '@react-keycloak-fork/web';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationNotificationInputProps {
    organizationNotificationConfigurations: IOrganizationNotificationConfiguration[];
    areWebhooksLoading: boolean;
    isWebhookUpdating: boolean;
    updateWebhook: (hookRequest: IPutOrganizationIntegrationRequest) => void;
    createWebhook: (hookRequest: IPostOrganizationIntegrationRequest) => void;
}

const validationSchema: yup.ObjectSchema<IOrganizationConfigurationForm> = yup.object().shape({
    webhook_url: yup
        .string()
        .required('Webhook URL is required.')
        .url('Make sure the webhook url is a properly formatted url.')
        // .matches(
        //     /^https?:\/\/((([a-zA-Z0-9-]+\.)+webhook\.office\.com(\/|$))|(([a-zA-Z0-9-]+\.)*logic\.azure\.com(:\d+)?\/workflows\/))/i,
        //     `URL should match Teams' webhook url or Teams' workflow url syntax.`
        // ),
        .matches(/^https?:\/\/([a-zA-Z0-9-]+\.)?webhook\.office\.com(\/|$)/i, 'Teams webhook URL should contain "webhook.office.com" in the domain')
        .required('Webhook URL is required.'),
});

const OrganizationNotificationInput: React.FC<IOrganizationNotificationInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { keycloak } = useKeycloak();
    const { organizationNotificationConfigurations, isWebhookUpdating, areWebhooksLoading, updateWebhook, createWebhook } = props;
    const { teamsWebhook, teamsWebhookId } = useMemo(() => {
        let teamsWebhook = '';
        let teamsWebhookId = undefined;
        if (organizationNotificationConfigurations.length > 0) {
            const config = organizationNotificationConfigurations.find((config) => config.notification_channel === NotificationChannelName.Teams);
            teamsWebhook = config?.webhook_url ?? '';
            teamsWebhookId = config?.id ?? undefined;
        }
        return { teamsWebhook, teamsWebhookId };
    }, [organizationNotificationConfigurations]);
    const theme = useTheme();
    const [isTeamsFieldsChanged, setIsTeamsFieldsChanged] = useState(false);

    const onTeamsFieldChange = () => {
        setIsTeamsFieldsChanged(true);
    };

    const {
        handleSubmit: teamFormsHandleSubmit,
        control: teamsFormControl,
        reset: teamsFormReset,
        formState: { isDirty: isTeamsFormDirty, errors: teamsFormErrors },
    } = useForm<IOrganizationConfigurationForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            webhook_url: teamsWebhook ?? '',
        },
    });

    useEffect(() => {
        teamsFormReset(
            {
                webhook_url: teamsWebhook,
            },
            { keepDirty: true, keepIsValid: true }
        );
    }, [teamsWebhook, keycloak.realm]);

    const onTeamsSubmit = (data: IOrganizationConfigurationForm) => {
        setIsTeamsFieldsChanged(false);
        teamsWebhookId
            ? updateWebhook({ id: teamsWebhookId, webhook_url: data.webhook_url, is_enabled: true })
            : createWebhook({
                  notification_channel: NotificationChannelName.Teams,
                  notification_type: NotificationIntegrationTypeType.AnomalyDetected,
                  org_slug: keycloak.realm!,
                  webhook_url: data.webhook_url,
              });
    };

    return (
        <Grid className={cx(classes.GridList)} item xs={12} container spacing={2}>
            <NotificationIntegrationTile
                integrationName={'Microsoft Teams'}
                customIcon={<MicrosoftTeams />}
                children={
                    <Stack
                        onChange={onTeamsFieldChange}
                        onSubmit={teamFormsHandleSubmit((data) => {
                            onTeamsSubmit(data);
                        })}
                        width={'100%'}
                        component={'form'}
                        id={'teams-integration-form'}
                        spacing={1.5}
                    >
                        {areWebhooksLoading ? (
                            <FormFieldLoadingSkeleton formField={'Channel Webhook'} htmlFor={'webhook_url'} />
                        ) : (
                            <FormField label={'Channel Webhook'} htmlFor={'webhook_url'}>
                                <Controller
                                    name={'webhook_url'}
                                    control={teamsFormControl}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            id={'webhook_url'}
                                            size='small'
                                            fullWidth={true}
                                            value={value}
                                            onChange={(e) => onChange(e.target.value)}
                                            error={!!teamsFormErrors.webhook_url}
                                            helperText={teamsFormErrors.webhook_url?.message}
                                        />
                                    )}
                                />
                            </FormField>
                        )}
                        <Stack alignItems={'flex-start'}>
                            <LoadingButton
                                loading={isWebhookUpdating}
                                loadingPosition={'start'}
                                startIcon={isWebhookUpdating ? <Icon></Icon> : <></>}
                                disabled={!isTeamsFormDirty || !isTeamsFieldsChanged}
                                form={'teams-integration-form'}
                                type={'submit'}
                                variant={'contained'}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                    </Stack>
                }
            />
            <NotificationIntegrationTile
                integrationName={'Slack'}
                customIcon={<Slack />}
                children={
                    <Grid className={cx(classes.SlackNotice)} alignItems={'flex-start'} justifyContent={'flex-start'} container xs={12} item>
                        <Grid item xs>
                            <ErrorOutline fontSize={'small'} className={cx(classes.AlertColor)} />
                        </Grid>
                        <Grid item xs={11}>
                            <Stack direction={'column'}>
                                <Typography fontWeight={theme.typography.fontWeightBold} variant={'body2'}>
                                    Coming Soon
                                </Typography>
                                <Typography variant={'body2'}>Slack integration is currently under development. Stay tuned for updates!</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                }
            />
        </Grid>
    );
};

const useStyles = customMakeStyles<IOrganizationNotificationInputProps>()((theme, props) => ({
    SlackNotice: { border: '1px solid  #D0D5DD', borderRadius: '8px', padding: '0.5rem' },
    GridList: {
        padding: '0rem 1rem 1rem 1rem',
    },
    AlertColor: { color: theme.palette.action.active },
}));

export { OrganizationNotificationInput };
