import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid } from '@mui/material';
import { SettingsContentHeader } from '../settings-content-header';
import { SettingsV2Divider } from '../settings-v2-divider';
import { SettingsDetailTile } from '../settings-detail-tile';
import { OrganizationNotificationInput } from './organization-notification-input';
import { useOrganizationNotificationSettingsApi } from '../../../api-hooks/notifications/use-organization-notification-settings-api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationNotificationsV2Props {}

const OrganizationNotificationsV2: React.FC<IOrganizationNotificationsV2Props> = (props) => {
    const { classes, cx } = useStyles(props);
    const { organizationNotificationConfigurations, updateWebhook, createWebhook, isWebhookUpdating, areWebhooksLoading } =
        useOrganizationNotificationSettingsApi({});

    return (
        <>
            <Grid container spacing={2}>
                <SettingsContentHeader title={'Organization Notifications'} subtitle={`Manage your organization's notification settings.`} />
                <SettingsV2Divider />
                <SettingsDetailTile
                    title={'Integrations'}
                    children={
                        <OrganizationNotificationInput
                            isWebhookUpdating={isWebhookUpdating}
                            organizationNotificationConfigurations={organizationNotificationConfigurations}
                            areWebhooksLoading={areWebhooksLoading}
                            updateWebhook={updateWebhook}
                            createWebhook={createWebhook}
                        />
                    }
                />
                {/*<Grid sx={{ paddingLeft: '1rem' }} item xs={12}>*/}
                {/*    <OrganizationNotificationInput*/}
                {/*        isTeamsWebhookUpdating={isTeamsWebhookUpdating}*/}
                {/*        teamsWebhook={teamsWebhook}*/}
                {/*        isTeamsWebhookLoading={isTeamsWebhookLoading}*/}
                {/*        updateTeamsWebhook={updateTeamsWebhook}*/}
                {/*    />*/}
                {/*</Grid>*/}
            </Grid>
        </>
    );
};

const useStyles = customMakeStyles<IOrganizationNotificationsV2Props>()((theme, props) => ({}));

export { OrganizationNotificationsV2 };
