export { ReactComponent as VegaLogo } from './Vega_logo.svg';
export { ReactComponent as VegaLogoShort } from './Vega_logo_short.svg';
export { ReactComponent as VegaLogoLarge } from './Vega_logo_large.svg';
export { ReactComponent as AwsLogo } from './aws-logo.svg';
export { ReactComponent as AzureLogo } from './microsoft.svg';
export { ReactComponent as GcpLogo } from './google-logo.svg';
export { ReactComponent as VegaCow } from './Veganout-Whole.svg';
export { ReactComponent as OneSmallStep } from './One_Small_Step_Grayscale.svg';
export { ReactComponent as OneGiantLeap } from './One_Giant_Leap_Grayscale.svg';
export { ReactComponent as LiftOff } from './Lift_Off_Grayscale.svg';
export { ReactComponent as CloudHeroPurple } from './Cloud_Hero_Purple.svg';
export { ReactComponent as CloudHeroTitle } from './Cloud_Hero_Title.svg';
export { ReactComponent as OktaLogo } from './Okta_Wordmark_Black_S.svg';
export { ReactComponent as SamlLogo } from './saml-logo.svg';
export { ReactComponent as Cosmoo } from './cosmoo.svg';
export { ReactComponent as CosmooNew } from './cosmoo_test.svg';
export { ReactComponent as WhiteVegaLogo } from './white_vega_logo.svg';
export { ReactComponent as WhiteVegaLogoShort } from './white_vega_log_short.svg';
export { ReactComponent as CosmooV2 } from './cosmoo_v2_compressed.svg';
export { ReactComponent as Slack } from './slack.svg';
export { ReactComponent as MicrosoftTeams } from './microsoft-teams.svg';
