import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { SharedReportInfo, SharedReports, useSharedReportsApi } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISharedReportsViewerProps {}

const SharedReportsViewController: React.FC<ISharedReportsViewerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { sharedEmbeddedUrl, isLoading, setSharedViewId, dashboardId, setDashboardId, sharedViewId } = useSharedReportsApi({});

    useEffect(() => {
        const reportId = window.location.pathname.split('/')[2];
        setDashboardId(reportId ?? '');
        const viewId = window.location.pathname.split('/')[4];
        setSharedViewId(viewId ?? null);
    }, [window.location.pathname]);

    return !dashboardId && !sharedViewId ? (
        <SharedReportInfo />
    ) : (
        <SharedReports viewId={sharedViewId} dashboardId={dashboardId} dashboardUrl={sharedEmbeddedUrl} isLoading={isLoading} />
    );
};

const useStyles = customMakeStyles<ISharedReportsViewerProps>()((theme, props) => ({}));

export { SharedReportsViewController };
