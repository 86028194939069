import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Alert,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { LinkAzureAccountForm, ICloudProviderAccount } from '@vegaplatformui/models';
import { SetterOrUpdater, useRecoilValue } from 'recoil';
import { ArrowBack, Close, Download } from '@mui/icons-material';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import { useFetchFileBlobAndDownload } from '../utilities/use-fetch-file-blob-and-download';
import { FormField } from '../forms';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountDiscoveryInProgressAlert } from './account-discovery-in-progress-alert';
import { DiscoveryDetails } from '../recoil/atom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinkAzureAccountDialogProps {
    isDialogOpen: boolean;
    onBackDrawer: () => void;
    onCloseDialog: () => void;
    accountToEdit?: ICloudProviderAccount;
    onSubmitLinkAzureAccountForm: (data: LinkAzureAccountForm) => void;
    setSnackbarOptions: SetterOrUpdater<CustomSnackBarOptions>;
    onOpenDeleteAccountDialog?: (account: ICloudProviderAccount) => void;
}

const validationSchema: yup.ObjectSchema<LinkAzureAccountForm> = yup.object().shape({
    subscription_id: yup.string().uuid('Subscription ID must be a valid UUID').required('Subscription ID is required'),
    account_alias: yup.string(),
    client_id: yup.string().uuid('Client ID/Application ID must be a valid UUID').required('Client ID is required'),
    client_secret: yup
        .string()
        .required('Client Secret Value is required')
        .matches(/^(?:(?!([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})).)*$/gm, {
            excludeEmptyString: false,
            message: 'The secret value itself is required, not the Secret ID.',
            name: 'Do Not Match UUID',
        }),
    tenant_id: yup.string().uuid('Tenant ID must be a valid UUID').required('Tenant ID is required'),
    enabled: yup.boolean().required(),
});

const LinkAzureAccountDrawer: React.FC<ILinkAzureAccountDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [cloudProvider, setCloudProvider] = React.useState<string | undefined>(undefined);
    const [isCloudProviderSelected, setIsCloudProviderSelected] = React.useState(false);
    const fetchFileBlobAndDownload = useFetchFileBlobAndDownload();
    const discoveryDetails = useRecoilValue(DiscoveryDetails);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState,
        formState: { errors, isDirty, isValid, isSubmitting, touchedFields, isSubmitSuccessful },
    } = useForm<LinkAzureAccountForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            account_alias: (props.accountToEdit && props.accountToEdit.account_name) ?? '',
            subscription_id: (props.accountToEdit && props.accountToEdit.account_id) ?? '',
            client_id:
                (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.clientId) ??
                (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.client_id) ??
                '',
            client_secret:
                (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.clientSecret) ??
                (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.secret_value) ??
                '',
            tenant_id:
                (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.tenantId) ??
                (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.tenant_id) ??
                '',
            enabled: props.accountToEdit?.enabled ?? true,
        },
    });

    useEffect(() => {
        if (props.accountToEdit !== undefined) {
            reset(
                {
                    account_alias: (props.accountToEdit && props.accountToEdit.account_name) ?? '',
                    subscription_id: (props.accountToEdit && props.accountToEdit.account_id) ?? '',
                    client_id:
                        (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.clientId) ??
                        (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.client_id) ??
                        '',
                    client_secret:
                        (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.clientSecret) ??
                        (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.secret_value) ??
                        '',
                    tenant_id:
                        (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.tenantId) ??
                        (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.tenant_id) ??
                        '',
                    enabled: props.accountToEdit?.enabled ?? true,
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
    }, [props.accountToEdit]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({});
        }
    }, [formState, reset, isSubmitSuccessful]);

    const onCloseDrawerAndResetState = () => {
        props.onCloseDialog();
        reset({});
    };

    const onBackDrawerAndResetState = () => {
        props.onBackDrawer();
        reset({});
    };

    const onSubmitForm: SubmitHandler<LinkAzureAccountForm> = (data) => {
        const formToSubmit = { ...data };
        props.onSubmitLinkAzureAccountForm(formToSubmit);
        props.onCloseDialog();
    };

    return (
        <Drawer
            PaperProps={{
                className: cx(classes.DrawerPaper),
            }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={props.isDialogOpen}
            onClose={onCloseDrawerAndResetState}
            aria-labelledby='link-azure-account-drawer'
            hideBackdrop={!props.accountToEdit}
            id={'link-azure-account-drawer'}
        >
            <DialogTitle variant={'h6'} id='link-azure-account-drawer-title'>
                <Grid container>
                    <Grid xs={10}>
                        <Stack className={cx(classes.DrawerTitle)} direction={'row'} justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                            {props.accountToEdit === undefined && (
                                <IconButton aria-label={'Back'} id={'link-azure-account-drawer-return'} onClick={onBackDrawerAndResetState}>
                                    <ArrowBack className={commonStyles.classes.DarkIconColor} />
                                </IconButton>
                            )}
                            <Stack direction={'column'}>
                                {(props.accountToEdit && 'Edit Azure Subscription') ?? 'Link Azure Subscription'}
                                {props.accountToEdit === undefined ? (
                                    <Typography variant={'body1'}>
                                        Click the button to access the Vega documentation, where you'll find detailed guidance and additional
                                        information.
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid xs={2}>
                        <IconButton
                            aria-label={'Close'}
                            id={'link-azure-account-drawer-close'}
                            className={cx(classes.CloseButton)}
                            onClick={onCloseDrawerAndResetState}
                        >
                            <Close className={commonStyles.classes.DarkIconColor} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Stack direction={'column'} className={cx(classes.DrawerContainer)} justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                    {props.accountToEdit === undefined && (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            variant={'outlined'}
                            type={'button'}
                            href={'https://docs.vegacloud.io/docs/providers/azure'}
                            target={'_blank'} //open in a new tab
                            rel={'noreferrer noopener'} //blocks communication between the new tab and the original tab (security measure)
                        >
                            Vega Azure Guide
                        </Button>
                    )}
                    {props.accountToEdit && discoveryDetails.is_discovery && <AccountDiscoveryInProgressAlert />}
                    <Stack
                        id={'link-azure-account-drawer-form'}
                        component={'form'}
                        spacing={2}
                        onSubmit={handleSubmit((data) => {
                            onSubmitForm(data);
                        })}
                    >
                        <Stack className={cx(classes.FormTitle)} direction={'column'} spacing={0}>
                            <Typography fontWeight={600} variant={'body1'}>
                                Account Details
                            </Typography>
                            <Typography className={cx(classes.FormContentWindow)} variant={'body2'}>
                                The Account or Canonical Name is automatically populated from your cloud service provider.
                            </Typography>
                        </Stack>
                        <FormField label='Subscription ID' htmlFor='subscription_id'>
                            <Controller
                                name={'subscription_id'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'subscription_id'}
                                        inputProps={{ readOnly: !!props.accountToEdit }}
                                        size='small'
                                        className={cx(classes.FormContentWindow)}
                                        error={!!errors.subscription_id}
                                        placeholder={'Enter Azure subscription ID'}
                                        helperText={errors.subscription_id?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Client ID' htmlFor='client_id'>
                            <Controller
                                name={'client_id'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'client_id'}
                                        size='small'
                                        className={cx(classes.FormContentWindow)}
                                        inputProps={{ readOnly: !!props.accountToEdit }}
                                        error={!!errors.client_id}
                                        placeholder={'Enter Azure client ID'}
                                        helperText={errors.client_id?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Client Secret Value' htmlFor='client_secret'>
                            <Controller
                                name={'client_secret'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'client_secret'}
                                        size='small'
                                        className={cx(classes.FormContentWindow)}
                                        error={!!errors.client_secret}
                                        placeholder={'Enter Azure client secret value'}
                                        helperText={errors.client_secret?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Tenant ID' htmlFor='tenant_id'>
                            <Controller
                                name={'tenant_id'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'tenant_id'}
                                        size='small'
                                        className={cx(classes.FormContentWindow)}
                                        inputProps={{ readOnly: !!props.accountToEdit }}
                                        error={!!errors.tenant_id}
                                        placeholder={'Enter Azure tenant ID'}
                                        helperText={errors.tenant_id?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Vega Account Alias (optional)' htmlFor='account_alias'>
                            <Controller
                                name={'account_alias'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        fullWidth={true}
                                        value={value}
                                        id={'account_alias'}
                                        size='small'
                                        className={cx(classes.FormContentWindow)}
                                        error={!!errors.account_alias}
                                        placeholder={'Create an alias'}
                                        helperText={
                                            (errors.account_alias?.message as string) ??
                                            `${props.accountToEdit ? 'Edit' : 'Create a'} nickname for this account.`
                                        }
                                    />
                                )}
                            />
                        </FormField>
                        <FormField className={cx(classes.IsEnabledField)} id={'enabled'} label='Account Status' htmlFor='enabled'>
                            <Controller
                                name={'enabled'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        className={cx(classes.EnabledSwitch)}
                                        control={<Switch id={'enabled'} onClick={() => setValue('enabled', !value)} checked={value} />}
                                        label={`This account is ${value ? 'enabled' : 'disabled'}`}
                                        labelPlacement='end'
                                    />
                                )}
                            />
                        </FormField>
                        {/*Just adding false in so it will never display*/}
                        {props.accountToEdit === undefined && false && (
                            <Typography className={cx(classes.FormContentWindow)} variant={'caption'}>
                                Accounts will automatically be tested after creation and there will be a discovery every 30 minutes to search for
                                errors.
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <Stack className={cx(classes.FormContentWindow)} direction={'row'} justifyContent='space-between' alignItems='center' spacing={0}>
                <DialogActions className={cx(classes.DialogActions, classes.DeleteButton)}>
                    {props.accountToEdit && (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            id={'link-azure-account-drawer-delete'}
                            disableElevation={true}
                            variant={'contained'}
                            onClick={() => {
                                const account = props.accountToEdit!;
                                props.onOpenDeleteAccountDialog && props.onOpenDeleteAccountDialog(account);
                                onCloseDrawerAndResetState();
                            }}
                            color={'error'}
                        >
                            Delete
                        </Button>
                    )}
                </DialogActions>
                <DialogActions className={cx(classes.DialogActions)}>
                    {/*<Button variant={'cancel'} disableElevation={true} variant={'contained'} color={'secondary'} autoFocus onClick={props.onCloseDialog}>*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                    {props.accountToEdit !== undefined ? (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            disableElevation={true}
                            type={'submit'}
                            variant={'contained'}
                            form={'link-azure-account-drawer-form'}
                        >
                            Save
                        </Button>
                    ) : (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            id={'link-azure-account-drawer-form-submit'}
                            disableElevation={true}
                            type={'submit'}
                            variant={'contained'}
                            form={'link-azure-account-drawer-form'}
                        >
                            Link
                        </Button>
                    )}
                </DialogActions>
            </Stack>
        </Drawer>
    );
};

const useStyles = customMakeStyles<ILinkAzureAccountDialogProps>()((theme, props) => ({
    EnabledSwitch: { marginLeft: '-0.45rem' },
    IsEnabledField: {
        width: '90%',
    },
    DialogActions: {
        marginRight: '0.3rem',
    },
    CloudFormationTitle: {
        fontWeight: 600,
    },
    FormTitle: {
        fontWeight: 600,
        marginTop: '1rem',
        marginBottom: '.5rem',
    },
    DrawerRoot: {
        zIndex: '1300 !important' as any,
    },
    DrawerTitle: {},
    DeleteButton: { marginLeft: '4rem' },
    DrawerContainer: {
        marginLeft: '3rem',
    },
    DrawerPaper: { width: '35%' },
    FormContentWindow: {
        width: '90%',
    },
    CloseButton: {
        float: 'right',
        marginRight: '-1.5rem',
        marginTop: '-1rem',
    },
    TemplateButton: {
        marginTop: '2rem',
    },
}));

export { LinkAzureAccountDrawer };
