import React, { useEffect, useMemo, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import useShepherdTour from './use-shepherd-tour';
import ShepherdBase, { TourOptions, StepOptions, StepOptionsButton } from 'shepherd.js';
import { useNavigate } from 'react-router-dom';
import { useRouteUrls } from '@vegaplatformui/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import 'shepherd.js/dist/css/shepherd.css';
import { Box, Stack, Typography } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IShepherdTourProps {}

const MenuShepherdTour: React.FC<IShepherdTourProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const navigate = useNavigate();
    const { routeUrls } = useRouteUrls({});
    const flags = useFlags();

    const steps: StepOptions[] = useMemo(() => {
        return [
            {
                id: 'first',
                title: 'The Menu Item Locations Have Changed',
                canClickTarget: false,
                scrollTo: true,
                attachTo: { element: '#menu-drawer', on: 'right' },
                text: 'The menu items have been updated, and the left-side navigation bar now only contains menu items related to Inform, Optimize, and Operate.',
                buttons: [
                    {
                        classes: 'shepherd-button-primary',
                        text: 'Next',
                        action: () => {
                            next();
                        },
                    } as StepOptionsButton,
                ],
                beforeShowPromise: function () {
                    return new Promise(function (resolve: any) {
                        setTimeout(function () {
                            if (document.getElementById('menu-drawer') !== null) resolve();
                        }, 200);
                    });
                },
            },
            {
                id: 'second',
                title: 'Other Menu Locations',
                canClickTarget: false,
                attachTo: { element: '#settings-appbar-menu', on: 'bottom-start' },
                extraHighlights: ['#profile-menu-button', '#help-menu', '#menu-drawer'],
                text: 'Some menu items have been moved up to the top-right header, including Settings, Help, and Profile.',
                buttons: [
                    {
                        classes: 'shepherd-button-primary',
                        text: 'Back',
                        action: () => {
                            back();
                        },
                    } as StepOptionsButton,
                    {
                        classes: 'shepherd-button-primary',
                        text: 'Next',
                        action: () => {
                            next();
                        },
                    } as StepOptionsButton,
                ],
            },
            {
                id: 'third',
                title: 'Navigate to Settings',
                canClickTarget: false,
                attachTo: { element: '#settings-appbar-menu', on: 'bottom-start' },
                text: 'Select Next to navigate to the Settings page.',
                buttons: [
                    {
                        classes: 'shepherd-button-primary',
                        text: 'Back',
                        action: () => {
                            back();
                        },
                    } as StepOptionsButton,
                    {
                        classes: 'shepherd-button-primary',
                        text: 'Next',
                        action: () => {
                            next();
                            navigate(`/${routeUrls.settings.route.path}`);
                        },
                    } as StepOptionsButton,
                ],
            },
            {
                id: 'fourth',
                title: 'Settings Menu',
                canClickTarget: false,
                scrollTo: true,
                attachTo: { element: '#settings-menu', on: 'right-start' },
                text: `Settings have been updated and are now split into Profile Settings and, if visible, Organizational Settings.`,
                buttons: [
                    {
                        classes: 'shepherd-button-primary',
                        text: 'Back',
                        action: () => {
                            back();
                        },
                    } as StepOptionsButton,
                    {
                        classes: 'shepherd-button-primary',
                        text: 'Finish',
                        action: () => {
                            complete();
                        },
                    } as StepOptionsButton,
                ],
                beforeShowPromise: function () {
                    return new Promise(function (resolve: any) {
                        setTimeout(function () {
                            if (window.location.pathname.includes('settings')) resolve();
                        }, 200);
                    });
                },
            },
        ] as StepOptions[];
    }, []);

    const tourOptions: TourOptions = {
        id: 'new-menu-tour',
        tourName: 'new-menu-tour',
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
            classes: cx(classes.CustomShepherd),
        },
        useModalOverlay: true,
    };

    const { tourObject } = useShepherdTour({ tourOptions, steps });

    const next = () => {
        tourObject.next();
    };

    const complete = () => {
        tourObject.complete();
        localStorage.setItem('menu_walkthrough', 'completed');
    };

    const back = () => {
        tourObject.back();
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const isWalkthroughComplete = localStorage.getItem('menu_walkthrough') ?? '';
            if (flags.uiMenuWalkthrough && !tourObject.isActive() && !isWalkthroughComplete) {
                tourObject.start();
            }
            return;
        }
    }, [flags.uiMenuWalkthrough]);

    return <></>;
};

const useStyles = customMakeStyles<IShepherdTourProps>()((theme, props) => ({
    CustomShepherd: {
        fontFamily: theme.typography.fontFamily,
        padding: 0,
        borderRadius: '8px',
        border: `1px solid #D0D5DD`,
        '& .shepherd-arrow::before': {
            // backgroundColor: `${theme.palette.grey[100]}60 !important`,
            border: `1px solid #D0D5DD`,
        },
        '& .shepherd-content': { padding: '1.5rem', borderRadius: '8px' },
        '& .shepherd-header': {
            padding: `0 !important`,
            backgroundColor: 'transparent !important ',
        },
        // '& .shepherd-target': {
        //     border: '2px solid black',
        //     boxShadow: `0 0px 9px rgb(65 117 204)`,
        // },
        // '& .shepherd-cancel-icon': {
        //     color: theme.palette.text.primary,
        //
        //     fontSize: `${theme.typography.h6.fontSize}`,
        // },
        '& .shepherd-title': {
            color: theme.palette.text.primary,
            display: theme.typography.h6.display,
            fontSize: `${theme.typography.h6.fontSize}`,
            lineHeight: `${theme.typography.h6.lineHeight}`,
            letterSpacing: `${theme.typography.h6.letterSpacing}`,
            fontWeight: 600,
        },
        '& .shepherd-progress': {
            marginRight: '0.5rem',
            marginTop: '0.5rem',
            position: 'absolute',
            left: '1.5rem',
            color: theme.palette.text.secondary,
            display: theme.typography.caption.display,
            fontSize: `${theme.typography.caption.fontSize}`,
            lineHeight: `${theme.typography.caption.lineHeight}`,
            letterSpacing: `${theme.typography.caption.letterSpacing}`,
        },
        '& .shepherd-text': {
            padding: '1.5rem 0',
            color: theme.palette.text.primary,
            fontWeight: 400,
            display: theme.typography.body2.display,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
        },
        '& .shepherd-footer': { padding: 0 },
        '& .shepherd-button': {
            minWidth: '4rem',
            padding: '4px 10px',
            textTransform: 'capitalize',
            borderRadius: '8px',
            fontFamily: `${theme.typography.fontFamily} !important`,
            fontSize: theme.typography.button.fontSize,
            lineHeight: '1.5rem',
            fontWeight: theme.typography.button.fontWeight,
            transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        },
        '& .shepherd-button-primary': {
            backgroundColor: `${theme.palette.primary.main}`,
            color: theme.palette.getContrastText(theme.palette.primary.main),
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.getContrastText(theme.palette.primary.dark),
            },
        },
        '& .shepherd-button-secondary': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.getContrastText(theme.palette.common.white),
            position: 'absolute',
            left: '1.5rem',
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
                color: theme.palette.getContrastText(theme.palette.grey[100]),
            },
            // border: `1px solid #D0D5DD`,
        },
    },
}));

export { MenuShepherdTour };
